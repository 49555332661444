<template>
  <div class="index">
    <div class="lang">
      <!-- <el-radio-group v-model="language" size="mini">
          <el-radio v-for="item in lang" :label="item.value" border :key="item.id">{{ item.label }}</el-radio>
      </el-radio-group>-->
    </div>
    <!-- 头部 -->
    <header>
      <!-- 导航 -->
      <div class="nav">
        <div class="navC">
          <div class="navLogo">
            <router-link to="/">
              <img style="max-width:119px;" src="https://trustpass.oss-cn-hongkong.aliyuncs.com/images/TPE_LOGO1_%E6%A8%AA%E7%89%88.png" alt="">
              <!-- <img src="../assets/images/logo.png" alt /> -->
            </router-link>
          </div>
          <ul>
            <li>
              <router-link to="/">主頁</router-link>
            </li>
            <li @click="serviceMove">
              <router-link to="/">我們的服務</router-link>
            </li>
            <li>
              <router-link to="/information/">兌換咨詢</router-link>
            </li>
            <li @click="findMove">
              <router-link to="/">聯絡我們</router-link>
            </li>
            <el-button class="navbtn" size="mini">
              <v-reserve></v-reserve>
            </el-button>
          </ul>
        </div>
      </div>
      <!-- banner -->
      <div class="bannerC">
        <div class="bannerCC">
          <div class="bannerTitle">匯通天下，無處不達</div>
          <v-exchange class="exchange"></v-exchange>
        </div>
      </div>
    </header>
    <div class="clear"></div>
    <!-- 主体内容 -->
    <div class="content">
      <div>
        <div class="contentTltle">
          <span style="color: #fe7903">{{ $t("message.content.huilv") }}</span>
          {{ $t("message.content.find") }}
        </div>
        <div style="text-align: center; font-family: Light">
          Exchange rate inquiry
        </div>
      </div>
      <!-- 电汇价 -->
      <div class="net-money">
        <p>
          {{ $t("message.content.wire") }} WIRE TRANSFER
          <span>{{ $t("message.content.data") }}：{{ Maxdata1 }}</span>
        </p>
        <table
          border="1"
          cellpadding="0"
          cellspacing="0"
          bordercolor="#E6E6E6"
          class="table-div"
        >
          <tr style="background: #dbdada; font-family: Medium">
            <td>{{ $t("message.content.wiremoney") }}</td>
            <td>{{ $t("message.content.bug") }}</td>
            <td>{{ $t("message.content.mai") }}</td>
          </tr>
          <tr v-for="t in tt" :key="t.id">
            <td
              style="
                display: flex;
                flex-wrap: wrap;
                height: 40px;
                justify-content: center;
              "
            >
              <div
                style="
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-around;
                "
              >
                <div>
                  <span
                    style="display: inline-block; width: 30px; height: 30px"
                  ></span>
                  <span>{{ t.base }}</span>
                </div>
                <div class="m1">
                  <img src="../assets/images/huan.png" alt />
                </div>
                <div>
                  <span
                    style="display: inline-block; width: 30px; height: 30px"
                  ></span>
                  <span>{{ t.code }}</span>
                </div>
              </div>
            </td>
            <td>{{ t.tt_buy }}</td>
            <td>{{ t.tt_sell }}</td>
          </tr>
        </table>
      </div>
      <!-- 现炒价 -->
      <div class="now-money">
        <p>
          現鈔價 FOREIGN CASH
          <span>更新時間：{{ Maxdata }}</span>
        </p>
        <div class="nowL" style="width: 100%">
          <table
            border="1"
            cellpadding="0"
            cellspacing="0"
            bordercolor="#E6E6E6"
          >
            <tr style="background: #dbdada; font-family: Medium">
              <td style="background: none">國家</td>
              <td>代號</td>
              <td>貨幣</td>
              <td>買入</td>
              <td>賣出</td>
            </tr>
            <tr v-for="all in allData1" :key="all.id">
              <td></td>
              <td>{{ all.code }}</td>
              <td>{{ all.name }}</td>
              <td>{{ all.buy_rate }}</td>
              <td>{{ all.sell_rate }}</td>
            </tr>
          </table>
        </div>
        <div class="clear"></div>
      </div>
      <div class="MoreE">
        <div style="M1"></div>
        <div>
          <button @click="dialogVisible2 = true">更多汇率</button>
        </div>
        <div style="M1"></div>
      </div>
    </div>
    <div class="Global">
      <div class="GlobalC">
        <div class="GlobalC-top">
          <div>
            全球匯款
            <span style="color: #fe7903">業務</span>
          </div>
          <span>Global remittance business</span>
        </div>
        <div class="GlobalC-bottom">
          <div>
            <div>
              <img
                src="../assets/images/new_bussiness_img1.png"
                alt
                width="100%"
              />
              <span>70個國家</span>
            </div>
          </div>
          <div>
            <div>
              <img
                src="../assets/images/new_bussiness_img2.png"
                alt
                width="100%"
              />
              <span>最優質的服務</span>
            </div>
          </div>
          <div>
            <div>
              <img
                src="../assets/images/new_bussiness_img3.png"
                alt
                width="100%"
              />
              <span>最良好的體驗</span>
            </div>
          </div>
        </div>
        <div class="GlobalC-bottom-mo">
          <el-carousel height="150px">
            <el-carousel-item>
              <div>
                <div class="light">
                  <img
                    src="../assets/images/new_bussiness_img1.png"
                    alt
                    width="100%"
                    height="260px"
                  />
                  <span>70個國家</span>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div>
                <div class="light">
                  <img
                    src="../assets/images/new_bussiness_img2.png"
                    alt
                    width="100%"
                    height="260px"
                  />
                  <span>最優質的服務</span>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div>
                <div class="light">
                  <img
                    src="../assets/images/new_bussiness_img3.png"
                    alt
                    width="100%"
                    height="260px"
                  />
                  <span>最良好的體驗</span>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="zxrx" style="width: 100%">
          <a
            rel="nofollow"
            target="_blank"
            href="http://p.qiao.baidu.com/cps/chat?siteId=14068382&amp;userId=29252090&amp;cp=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D&amp;cr=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D&amp;cw=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D"
          >
            <button>
              <img src="../assets/images/new_bussiness_btn_icon.png" alt />
              咨詢熱綫
            </button>
          </a>
        </div>
      </div>
    </div>
    <!-- 汇率查询 -->
    <div class="query">
      <div class="queryC">
        <div class="queryC-top">
          <div>
            匯款
            <span style="color: #fe7903">流程</span>及案例
          </div>
          <span>Remittance process and case</span>
        </div>
        <div class="queryC-bottom">
          <div class="cicletu">
            <div>
              <img src="../assets/images/process_content_icon1.png" alt />
            </div>
            <div>
              <img src="../assets/images/process_content_icon2.png" alt />
            </div>
            <div>
              <img src="../assets/images/process_content_icon3.png" alt />
            </div>
            <div>
              <img src="../assets/images/process_content_icon4.png" alt />
            </div>
            <div>
              <img src="../assets/images/process_content_icon5.png" alt />
            </div>
          </div>
          <div class="cicle">
            <hr style="width: 100%; background: red" />
            <div>
              <img src="../assets/images/process_content_seq1.png" alt />
            </div>
            <div>
              <img src="../assets/images/process_content_seq2.png" alt />
            </div>
            <div>
              <img src="../assets/images/process_content_seq3.png" alt />
            </div>
            <div>
              <img src="../assets/images/process_content_seq4.png" alt />
            </div>
            <div>
              <img src="../assets/images/process_content_seq5.png" alt />
            </div>
          </div>
          <div class="cicletext">
            <div>
              <div>匯款给指定用戶</div>
              <span>請將港幣XXXX轉賬到以下賬戶:中國銀行/ Chan Tai</span>
            </div>
            <div>
              <div>提供截圖</div>
              <span>轉賬成功後將轉賬的記錄截圖給我方</span>
            </div>
            <div>
              <div>提供賬號資料</div>
              <span>請提供妳的大陸收款賬戶信息，包括：賬戶號碼，支行名稱</span>
            </div>
            <div>
              <div>我方確認找換</div>
              <span>我方確認收款後，將壹個小時內付款戶:中國銀行/ Chan Tai</span>
            </div>
            <div>
              <div>收據返還</div>
              <span>付款後將有收據返回給您</span>
            </div>
          </div>
        </div>
        <div class="queryC-bottom-mo">
          <div>
            <div></div>
            <div>
              <div>匯款给指定用戶</div>
              <span>請將港幣XXXX轉賬到以下賬戶:中國銀行/ Chan Tai</span>
            </div>
          </div>
          <div>
            <div></div>
            <div>
              <div>提供截圖</div>
              <span>轉賬成功後將轉賬的記錄截圖給我方</span>
            </div>
          </div>
          <div>
            <div></div>
            <div>
              <div>提供賬號資料</div>
              <span>請提供妳的大陸收款賬戶信息，包括：賬戶號碼，支行名稱</span>
            </div>
          </div>
          <div>
            <div></div>
            <div>
              <div>我方確認找換</div>
              <span>我方確認收款後，將壹個小時內付款戶:中國銀行/ Chan Tai</span>
            </div>
          </div>
          <div>
            <div></div>
            <div>
              <div>收據返還</div>
              <span>付款後將有收據返回給您</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我们的服务 -->
    <div class="service">
      <div class="serviceC">
        <div class="serviceC-top">
          <div>
            我們的
            <span style="color: #fe7903">服務</span>
          </div>
          <span>Global remittance business</span>
        </div>
        <div class="serviceC-bottom">
          <div>
            <div class="ser-c">
              <div>
                <img src="../assets/images/service_content1_img.png" alt />
              </div>
              <div>
                <div>
                  <img src="../assets/images/service_content1_icon.png" alt />
                </div>
                <div>
                  <div>主營貨幣</div>
                  <div>
                    <img src="../assets/images/service_content_line.png" alt />
                  </div>
                  <div>
                    <span>主營港幣、人民幣、日元、美元及其他各國貨幣</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="ser-c">
              <div>
                <img src="../assets/images/service_content3_img.png" alt />
              </div>
              <div>
                <div>
                  <img src="../assets/images/service_content3_icon.png" alt />
                </div>
                <div>
                  <div>全球兌換</div>
                  <div>
                    <img src="../assets/images/service_content_line.png" alt />
                  </div>
                  <div>
                    <span
                      >中港澳及世界各地匯款，現金交易，外幣兌換，人民幣兌換外幣等</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="ser-c">
              <div>
                <img src="../assets/images/service_content4_img.png" alt />
              </div>
              <div>
                <div>
                  <img src="../assets/images/service_content4_icon.png" alt />
                </div>
                <div>
                  <div>免費跨境服務咨詢</div>
                  <div>
                    <img src="../assets/images/service_content_line.png" alt />
                  </div>
                  <div>
                    <span
                      >我們專業的香港團隊為你提供任何跨境服務咨詢，包括跨境匯款，香港保險等等，希望在跨境任何業務都能夠幫到你</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="ser-c">
              <div>
                <img src="../assets/images/service_content5_img.png" alt />
              </div>
              <div>
                <div>
                  <img src="../assets/images/service_content5_icon.png" alt />
                </div>
                <div>
                  <div>外幣兌換預訂服務</div>
                  <div>
                    <img src="../assets/images/service_content_line.png" alt />
                  </div>
                  <div>
                    <span
                      >不希望去到找換店先發現沒有需要的外幣？不用擔心，我們提供線上或致電預定外幣，超過40種不同的貨幣等你來換</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 寻找我们 -->
    <div class="find">
      <div class="findC">
        <div class="findC-top">
          <div>
            聯係
            <span style="color: #fe7903">我們</span>
          </div>
          <span>Global remittance business</span>
        </div>
        <div class="findC-bottom">
          <div class="route">
            <div class="routerC">
              <div>
                <p>上環總店</p>
                <p>{{ $t("message.find.tianxia") }}</p>
                <p>上環總店聯繫方式：(852) 34626432</p>
                <p>傳真(FAX):(852) 34604910</p>
              </div>
              <div>
                <p class="routertitle">
                  <span>
                    <img src="../assets/images/contact_ad_icon.png" alt />
                  </span>
                  <span class="routerspan1">具體地址</span>
                </p>
                <p class="routerspan2">上環德輔道中254號金融商業大廈地下A舖</p>
              </div>
              <div>
                <p class="routertitle">
                  <span>
                    <img src="../assets/images/contact_bus_icon.png" alt />
                  </span>
                  <span class="routerspan1">{{
                    $t("message.find.gongjiao")
                  }}</span>
                </p>
                <p class="routerspan2">
                  深圳灣直通巴士至上環信德中心->步行至地鐵站B出口->過馬路
                </p>
              </div>
              <div>
                <p class="routertitle">
                  <span>
                    <img src="../assets/images/contact_subway_icon.png" alt />
                  </span>
                  <span class="routerspan1">{{
                    $t("message.find.ditie")
                  }}</span>
                </p>
                <p class="routerspan2">上環站B出口對面</p>
              </div>
            </div>
          </div>
          <div class="map">
            <baidu-map class="mmap" :center="center2" :zoom="zoom">
              <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
              <bm-marker
                :position="center2"
                :dragging="true"
                animation="BMAP_ANIMATION_BOUNCE"
              ></bm-marker>
            </baidu-map>
          </div>
        </div>
      </div>
    </div>
    <!-- 尾页 -->
    <v-footer></v-footer>
    <!-- 背景 -->
    <div class="bg" @click="foldOn"></div>
    <!-- 底部導航 -->
    <div class="call">
      <div>
        <div class="callphone">
          <!-- <img src="../assets/images/new_float_icon1.png" alt />
          <img class="callphones" src="../assets/images/float_phone.png" alt /> -->
          <el-tooltip class="item" effect="dark" placement="left">
            <div slot="content" class="slot">
              （上環總店）聯係方式 : (852) 3462 6432
            </div>
            <div>
              <img src="../assets/images/new_float_icon1.png" alt />
            </div>
          </el-tooltip>
        </div>
        <div class="callmail">
          <!-- <a href="mailto:hkchengtong@163.com">
            <img src="../assets/images/float_icon22.png" alt />
          </a>
          <img class="callmails" src="../assets/images/float_icon2.png" alt /> -->
          <el-tooltip class="item" effect="dark" placement="left">
            <div slot="content" class="slot1">
              e-mail : enquiry@trustpassex.com
            </div>
            <div>
              <a href="mailto:hkchengtong@163.com">
                <img src="../assets/images/float_icon22.png" alt />
              </a>
            </div>
          </el-tooltip>
        </div>
        <div class="callzixun">
          <!-- <a
            rel="nofollow"
            target="_blank"
            href="http://p.qiao.baidu.com/cps/chat?siteId=14068382&userId=29252090&cp=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D&cr=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D&cw=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D"
          >
            <img src="../assets/images/new_float_icon4.png" alt />
          </a>
          <img
            class="callzixuns"
            src="../assets/images/float_advisory.png"
            alt
          /> -->
          <el-tooltip class="item" effect="dark" placement="left">
            <div slot="content" class="slot3">
              在綫諮詢
            </div>
            <div>
              <a
                rel="nofollow"
                target="_blank"
                href="http://p.qiao.baidu.com/cps/chat?siteId=14068382&userId=29252090&cp=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D&cr=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D&cw=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D"
              >
                <img src="../assets/images/new_float_icon4.png" alt />
              </a>
            </div>
          </el-tooltip>
        </div>
        <div class="callqr">
          <img class="qr" src="../assets/images/new_float_icon3.png" alt />
          <img class="qrcode" src="../assets/images/new_float_code.png" alt />
        </div>
        <div class="JJ">
          <img class="J" src="../assets/images/new_float_icon5.png" alt />
          <!-- <img src="../assets/images/float_back.png" alt=""> -->
        </div>
      </div>
    </div>
    <div>
      <div class="call-c">
        <div>
          <i class="el-icon-phone-outline"></i>
          <a href="tel:94262039">熱綫咨詢</a>
        </div>
        <div>
          <i class="el-icon-search"></i>
          <a @click="dialogVisible = true">微信咨詢</a>
        </div>
      </div>
    </div>
    <el-dialog class="er" :visible.sync="dialogVisible" width="30%">
      <img class="erweima" src="../assets/images/erweima.png" alt />
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible2"
      width="70%"
      :before-close="handleClose"
    >
      <div class="now-money">
        <p>
          現鈔價 FOREIGN CASH
          <span>更新時間：{{ Maxdata }}</span>
        </p>
        <div class="dialognowL" style="width: 100%">
          <table
            border="1"
            cellpadding="0"
            cellspacing="0"
            bordercolor="#E6E6E6"
          >
            <tr style="background: #dbdada; font-family: Medium">
              <td style="background: none">國家</td>
              <td>代號</td>
              <td>貨幣</td>
              <td>買入</td>
              <td>賣出</td>
            </tr>
            <tr v-for="all in dialogdata" :key="all.id">
              <td></td>
              <td>{{ all.code }}</td>
              <td>{{ all.name }}</td>
              <td>{{ all.buy_rate }}</td>
              <td>{{ all.sell_rate }}</td>
            </tr>
          </table>
        </div>
        <div class="clear"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="databtn" type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
// import Vue from 'vue'
import Nav from "./nav"; // 导航菜单组件
import Footer from "./footer"; // 尾部组件
import Exchange from "./exchange"; // 计算汇率组件
import reserve from "./reserve"; // 预约弹框组件

export default {
  name: "index",
  data() {
    return {
      // language: 0,
      // lang: [{
      //   label: this.$t('message.zh'),
      //   value: 0
      // }, {
      //   label: this.$t('message.en'),
      //   value: 1
      // }],
      dialogVisible2: false,
      dialogVisible: false,
      navList: ["主页", "网上预约", "货币汇率", "我们的服务", "联系我们"], // 导航数据
      allData1: [], // 首页现金汇率数据
      dialogdata: [], // 弹框汇率数据
      allDataright: [], // 表格左边汇率数据
      center: { lng: 114.236208, lat: 22.314243 }, // 地图api坐标
      center2: { lng: 114.163409, lat: 22.289542 }, // 地图api坐标
      zoom: 20,
      tt: [],
      dataMax1: [],
      Maxdata1: "",
      dataMax: [],
      Maxdata: "",
      list: [
        "http://localhost:8080/static/img/banner1.28ab8b6.jpg",
        "http://localhost:8080/static/img/banner2.e3c5d04.jpg",
      ],
    };
  },
  // watch: {
  //   language: function (val) {
  //     val === 0 ? this.$i18n.locale = 'zh' : this.$i18n.locale = 'en'
  //     Vue.set(this.lang, 0, {label: this.$t('message.zh'), value: 0})
  //     Vue.set(this.lang, 1, {label: this.$t('message.en'), value: 1})
  //   }
  // },
  methods: {
    serviceMove() {
      this.$router.push({ path: "/" });
      $("html, body").animate({ scrollTop: $(".service").offset().top }, 1200);
    },
    findMove() {
      this.$router.push({ path: "/" });
      $("html, body").animate({ scrollTop: $(".find").offset().top }, 1200);
    },
    move() {
      let h = $("header").height();
      $(window).scroll(function () {
        if ($(window).scrollTop() >= h) {
          $("nav").css({ position: "fixed", top: "0", background: "white" });
        } else {
          $("nav").css({ position: "static", zIndex: "10" });
        }
      });
    },
    // 获取汇率
    getexchange() {
      let notesapi = "https://api.trustpassex.com/v1/rates/notes";
      let api1 = "https://api.trustpassex.com/v1/rates/tt";
      axios
        .get(notesapi, {
          headers: { language: "cht" },
        })
        .then((response) => {
          var r = response.data.data.filter(function (x) {
            return x.code === "CNY" || x.code === "USD" || x.code === "EUR";
          });
          console.log(r);
          for (let i = 0; i < r.length; i++) {
            this.allData1.push(r[i]);
          }
          for (let i = 0; i < response.data.data.length; i++) {
            this.dataMax.push(
              new Date(response.data.data[i].updated_at).getTime() - 28800000
            );
          }
          this.Maxdata = this.$moment(
            Math.max.apply(null, this.dataMax)
          ).format("YYYY-MM-DD HH:MM");
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(notesapi, {
          headers: { language: "cht" },
        })
        .then((response) => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.dialogdata.push(response.data.data[i]);
          }
          for (let i = 0; i < response.data.data.length; i++) {
            this.dataMax.push(
              new Date(response.data.data[i].updated_at).getTime() - 28800000
            );
          }
          this.Maxdata = this.$moment(
            Math.max.apply(null, this.dataMax)
          ).format("YYYY-MM-DD HH:MM");
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(api1)
        .then((response) => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.tt.push(response.data.data[i]);
          }
          for (let i = 0; i < response.data.data.length; i++) {
            this.dataMax1.push(
              new Date(response.data.data[i].updated_at).getTime() - 28800000
            );
          }
          this.Maxdata1 = this.$moment(
            Math.max.apply(null, this.dataMax1)
          ).format("YYYY-MM-DD HH:MM");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    foldOff() {
      $(".fold").css({ left: "0", transition: "all 0.8s ease-in-out" });
      $(".bg").css("display", "block");
      $(".el-unfold").css("display", "block");
    },
    foldOn() {
      $(".fold").css({ left: "-60%", transition: "all 0.8s ease-in-out" });
      $(".bg").css("display", "none");
      $(".el-unfold").css("display", "none");
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
  mounted() {
    // let testapi1 = 'https://api-dev.cateraway.com/food/list'
    // axios.get(testapi1).then(response => {
    //   console.log(response)
    // }).catch(err => {
    //   console.log('获取失败')
    // })
    this.getexchange();
    this.move();
    // this.$i18n.locale === 'zh' ? this.language = 0 : this.language = 1
  },
  components: {
    "v-reserve": reserve,
    "v-nav": Nav,
    "v-footer": Footer,
    "v-exchange": Exchange,
  },
  updated() {
    $(function () {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 1000) {
          $(".J").fadeIn(1000);
        } else {
          $(".J").fadeOut(500);
        }
      });
      $(".J").click(function () {
        $("html, body").animate({ scrollTop: 0 });
      });
    });
  },
};
</script>

<style lang="scss">
@import "../../static/css/slick.css";
@import "../common/font/font.css";
.el-tooltip__popper.is-dark {
  background: #e60012;
  .slot {
    font-size: 16px;
    font-weight: bold;
  }
  .slot1 , .slot3{
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0;
  }
  .slot3{
    padding: 10px 35px;
  }
}
.el-tooltip__popper .popper__arrow::after {
  border-left-color: #e60012 !important;
}
.index .GlobalC-bottom-mo {
  display: none;
}
.index .queryC-bottom-mo {
  display: none;
}
.index .nav {
  position: fixed;
  top: 0px;
  font-family: Regular;
  width: 100%;
  background: white;
  height: 70px;
  z-index: 9999;
}
.index .navC {
  width: 70%;
  height: 70px;
  line-height: 70px;
  margin: auto;
}
.index .navC > div {
  float: left;
  margin-left: 50px;
}
.index .navC > ul {
  float: right;
  margin-right: 50px;
}
.index .navC > ul > li {
  display: inline-block;
  padding: 0 20px;
}
.navC > ul > li > a {
  color: black;
  text-decoration: none;
}
.navC > ul > button {
  color: white;
  background: #e60012;
  border: none;
  border-radius: 10px;
}
.navC > ul > li:hover a {
  color: #e60012;
  cursor: pointer;
}
.navC > ul > button > span > div > button {
  padding: 0;
  color: white;
}
.prompt {
  display: none;
}
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  left: 0px;
  top: 0px;
  z-index: 2;
  display: none;
}
.v-modal {
  display: none;
}
.label {
  width: 80px;
  cursor: pointer;
  font-size: 13px;
  display: none;
  text-align: center;
  position: fixed;
  right: 3rem;
  bottom: 20.5rem;
  font-family: "微软雅黑";
}
.label > button,
.label > span {
  outline: none;
  border: none;
  background: black;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
}
.label > button:hover,
.label > span:hover {
  background: #d4ae7f;
  transition: all 0.5s ease-in-out;
}
.label > span:hover span {
  display: block;
  color: white;
}
.label > span:hover i {
  display: none;
}
.label > span > span {
  display: none;
}
.label > button > div {
  width: 80px;
  height: 80px;
}
#btn > div > button {
  color: #d4ae7f;
  line-height: 50px;
}
.index .label > button:hover .el-button--text {
  color: white;
}
.label > span {
  text-align: center;
  line-height: 80px;
  width: 80px;
  height: 80px;
  display: inline-block;
  color: white;
  font-size: 13px;
}
nav {
  width: 100%;
  height: 75px;
  padding: 0 15px;
}
.navList {
  height: 75px;
  line-height: 75px;
  margin: 0;
  float: left;
}
.bannerC {
  margin-top: 70px;
  height: 530px;
  background-image: url("../assets/images/banner_bg.png");
  background-size: 100% 100%;
}
.bannerCC {
  width: 80%;
  margin: auto;
}
.bannerTitle {
  font-family: Medium;
  font-size: 50px;
  color: #fe7903;
  text-align: center;
  padding: 14px 0;
}
/* logo 导航*/
.logo {
  height: 75px;
  float: left;
}
.navList a,
.navList span {
  color: black;
}
.navList button {
  outline: none;
}
.navList li {
  cursor: pointer;
  display: inline-block;
  margin: 0px 1.5rem;
}
.el-form {
  width: 80% !important;
  margin: auto !important;
}
nav .el-icon-s-operation,
nav .el-icon-s-unfold {
  display: none;
}
.navList li a:hover {
  text-decoration: none;
}
li {
  list-style: none;
}
.clear {
  clear: both;
}
// 好處
.index .good {
  width: 100%;
  background: #f7f7f7;
}
.goodC,
.GlobalC,
.queryC,
.serviceC,
.findC {
  overflow: hidden;
  width: 70%;
  margin: auto;
}
.goodC-top,
.GlobalC-top,
.queryC-top,
.serviceC-top,
.findC-top {
  text-align: center;
  padding: 4% 0;
}
.goodC-top > div,
.GlobalC-top > div,
.queryC-top > div,
.serviceC-top > div,
.findC-top > div {
  font-size: 30px;
  font-family: Medium;
}
.goodC-top > span,
.GlobalC-top > span,
.queryC-top > span,
.serviceC-top > span,
.findC-top > span {
  font-size: 18px;
  font-family: Light;
}
.goodC-bottom,
.GlobalC-bottom {
  display: flex;
  flex-wrap: wrap;
}
.goodC-bottom > div {
  margin: 0 auto 4% auto;
  flex: 0 0 50%;
  box-sizing: border-box;
}
.goodC-bottom > div > div {
  float: left;
}
.goodC-bottom > div > div:nth-child(1) {
  width: 45%;
  text-align: center;
}
.goodC-bottom > div > div:nth-child(2) {
  width: 45%;
}
.text {
  padding: 30px 30px;
  box-sizing: border-box;
}
.text > div:nth-child(1) {
  font-family: Regular;
  font-size: 20px;
}
.text > div:nth-child(3) {
  font-family: Light;
}
// 全球
.Global {
  width: 100%;
  background: white;
}
.GlobalC-bottom > div {
  padding: 0 20px;
  margin: 0 0 4% 0;
  text-align: center;
  flex: 0 0 33.333%;
}
.GlobalC-bottom > div > div {
  position: relative;
}
.GlobalC-bottom > div > div > span {
  position: absolute;
  display: block;
  width: 100%;
  margin: auto;
  bottom: 30px;
  font-size: 22px;
  color: white;
}
.Global .zxrx button {
  width: 214px;
  height: 42px;
  border: none;
  background: #fe7903;
  color: white;
  margin: 0 auto 3% auto;
  border-radius: 20px;
  display: block;
  height: 42px;
  line-height: 42px;
}
/* 汇率查询 */
.MoreE {
  width: 100%;
  overflow: hidden;
  margin: 70px 0 0 0;
}
.MoreE > div:nth-child(1) {
  float: left;
  width: 40%;
  border-bottom: 1px solid #dbdada;
  padding: 10px 0;
}
.MoreE > div:nth-child(2) {
  float: left;
  width: 20%;
  text-align: center;
}
.MoreE > div:nth-child(2) > button {
  border: 2px solid #fe7903;
  background: white;
  font-family: Medium;
  padding: 5px 10px;
  color: #fe7903;
}
.MoreE > div:nth-child(3) {
  float: right;
  width: 40%;
  border-bottom: 1px solid #dbdada;
  padding: 10px 0;
}
.queryC-bottom {
  margin-bottom: 3%;
}
.queryC-bottom > .cicletu {
  display: flex;
  flex-wrap: wrap;
}
.cicletu > div,
.cicletext > div {
  text-align: center;
  flex: 0 0 20%;
}
.cicletu img {
  width: 100px;
  height: 100px;
}
.cicle {
  padding: 10px 0;
  position: relative;
}
.cicle > div {
  position: absolute;
  top: 10px;
}
.cicle > div:nth-child(2) {
  left: 8.5%;
}
.cicle > div:nth-child(3) {
  left: 29%;
}
.cicle > div:nth-child(4) {
  left: 49%;
}
.cicle > div:nth-child(5) {
  left: 69%;
}
.cicle > div:nth-child(6) {
  left: 89%;
}
.cicletext {
  display: flex;
  flex-wrap: wrap;
}
.cicletext > div > div {
  font-family: Medium;
  font-size: 18px;
}
.cicletext > div > span {
  font-family: Light;
  font-size: 13px;
  width: 70%;
  margin: auto;
  display: block;
}
/* 头部 */
header {
  width: 100%;
}
.exchange {
  width: 60%;
  height: 414px;
  margin: auto;
  background-image: url("../assets/images/banner_table_bg.png");
  background-size: 100% 100%;
  border-radius: 5px;
}
.el-button.el-button--text:hover span {
  color: white;
}
/* 主体内容 */
.net-money > p > span,
.now-money > p > span {
  display: inline-block;
  float: right;
  margin-right: 2%;
  font-size: 14px;
}
.net-money table tr:nth-child(2) div div:nth-child(1) span:nth-child(1) {
  background: url("../assets/images/hk.png") no-repeat;
}
.net-money table tr:nth-child(2) div div:nth-child(3) span:nth-child(1) {
  background: url("../assets/images/cny.png") no-repeat;
}
.net-money table tr:nth-child(3) div div:nth-child(1) span:nth-child(1) {
  background: url("../assets/images/usa.png") no-repeat;
}
.net-money table tr:nth-child(3) div div:nth-child(3) span:nth-child(1) {
  background: url("../assets/images/cny.png") no-repeat;
}
.index .content {
  overflow: hidden;
  width: 75%;
  margin: 7% auto;
}
.contentTltle {
  font-size: 35px;
  font-family: Medium;
  margin: auto;
  width: 620px;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  text-align: center;
}
.content li {
  display: inline-block;
}
.nowL {
  float: left;
}
.nowR {
  float: right;
}
.net-money p,
.now-money p {
  border-left: 5px solid;
  font-family: Medium;
  font-size: 18px;
  width: 100%;
  height: 36px;
  color: #e60012;
  line-height: 36px;
  text-indent: 20px;
  margin: 20px 0px 0px 0px;
}
.net-tag li {
  margin: 0px 14%;
  font-size: 14px;
}
.net-money table {
  font-family: Light;
  font-size: 15px;
  text-align: center;
  width: 100%;
  margin: 10px 0px;
  background-color: white;
}
.net-money table tr {
  height: 40px;
}
.net-money table img {
  float: left;
}
.net-money table span {
  width: 100px;
  text-align: center;
  line-height: 30px;
  float: left;
  height: 30px;
  display: inline-block;
}
.m1 {
  margin-right: 1.5rem;
}
.nowL table,
.dialognowL table {
  text-align: center;
  margin: 20px 0px 0px 0px;
  width: 100%;
  font-size: 15px;
  font-family: Light;
}
.nowL table td,
.dialognowL table td {
  height: 40px;
}
.nowL table tr:nth-child(2) td:nth-child(1) {
  background: url("../assets/images/cny.png") center no-repeat;
}
.nowL table tr:nth-child(3) td:nth-child(1) {
  background: url("../assets/images/AUD.png") center no-repeat;
}
.nowL table tr:nth-child(4) td:nth-child(1) {
  background: url("../assets/images/USD.png") center no-repeat;
}
.dialognowL table tr:nth-child(2) td:nth-child(1) {
  background: url("../assets/images/AED.png") center no-repeat;
}
.dialognowL table tr:nth-child(3) td:nth-child(1) {
  background: url("../assets/images/AUD.png") center no-repeat;
}
.dialognowL table tr:nth-child(4) td:nth-child(1) {
  background: url("../assets/images/CAD.png") center no-repeat;
}
.dialognowL table tr:nth-child(5) td:nth-child(1) {
  background: url("../assets/images/CHF.png") center no-repeat;
}
.dialognowL table tr:nth-child(6) td:nth-child(1) {
  background: url("../assets/images/cny.png") center no-repeat;
}
.dialognowL table tr:nth-child(7) td:nth-child(1) {
  background: url("../assets/images/DKK.png") center no-repeat;
}
.dialognowL table tr:nth-child(8) td:nth-child(1) {
  background: url("../assets/images/EUR.png") center no-repeat;
}
.dialognowL table tr:nth-child(9) td:nth-child(1) {
  background: url("../assets/images/GBP.png") center no-repeat;
}
.dialognowL table tr:nth-child(10) td:nth-child(1) {
  background: url("../assets/images/IDR.png") center no-repeat;
}
.dialognowL table tr:nth-child(11) td:nth-child(1) {
  background: url("../assets/images/JPY.png") center no-repeat;
}
.dialognowL table tr:nth-child(12) td:nth-child(1) {
  background: url("../assets/images/KRW.png") center no-repeat;
}
.dialognowL table tr:nth-child(13) td:nth-child(1) {
  background: url("../assets/images/KWD.png") center no-repeat;
}
.dialognowL table tr:nth-child(14) td:nth-child(1) {
  background: url("../assets/images/MOP.png") center no-repeat;
}
.dialognowL table tr:nth-child(15) td:nth-child(1) {
  background: url("../assets/images/MYR.png") center no-repeat;
}
.dialognowL table tr:nth-child(16) td:nth-child(1) {
  background: url("../assets/images/NZD.png") center no-repeat;
}
.dialognowL table tr:nth-child(17) td:nth-child(1) {
  background: url("../assets/images/PHP.png") center no-repeat;
}
.dialognowL table tr:nth-child(18) td:nth-child(1) {
  background: url("../assets/images/SEK.png") center no-repeat;
}
.dialognowL table tr:nth-child(19) td:nth-child(1) {
  background: url("../assets/images/SGD.png") center no-repeat;
}
.dialognowL table tr:nth-child(20) td:nth-child(1) {
  background: url("../assets/images/THB.png") center no-repeat;
}
.dialognowL table tr:nth-child(21) td:nth-child(1) {
  background: url("../assets/images/TWD.png") center no-repeat;
}
.dialognowL table tr:nth-child(22) td:nth-child(1) {
  background: url("../assets/images/USD.png") center no-repeat;
}
.dialognowL table tr:nth-child(23) td:nth-child(1) {
  background: url("../assets/images/VND.png") center no-repeat;
}
.dialognowL table tr:nth-child(24) td:nth-child(1) {
  background: url("../assets/images/ZAR.png") center no-repeat;
}
.query {
  width: 100%;
  background: #f7f7f7;
}
/* 我们的服务 */
.service {
  width: 100%;
  background: white;
}
.serviceC-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.serviceC-bottom > div {
  flex: 0 0 23%;
  margin: 20px 0;
}
.ser-c {
  width: 93%;
  height: 600px;
  margin: auto;
  box-shadow: 7px 10px 10px #d9d5d5;
}
.ser-c > div:nth-child(1) img {
  width: 100%;
}
.ser-c > div:nth-child(2) {
  padding-bottom: 15px;
}
.ser-c > div:nth-child(2) > div:nth-child(1) {
  text-align: center;
  margin: 15px 0;
}
.ser-c > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) {
  font-family: Regular;
  font-size: 20px;
  text-align: center;
  margin: 10px 0;
}
.ser-c > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) {
  text-align: center;
  margin: 10px 0;
}
.ser-c > div:nth-child(2) > div:nth-child(2) > div:nth-child(3) {
  font-family: Light;
  text-align: center;
  margin: 10px 0;
  width: 63%;
  margin: auto;
}
.b1 {
  position: fixed;
  right: 0;
  bottom: 2rem;
}
.b1 img {
  display: block;
}
.el-radio-group {
  height: 75px;
  line-height: 87px !important;
  float: right;
}
.zxrx:hover a {
  text-decoration: none;
}
/* 寻找我们 */
.find {
  overflow: hidden;
  width: 100%;
}
.map {
  width: 700px;
}
.mmap {
  width: 100%;
  height: 100%;
}
.findC-bottom {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 10px 100px 10px;
  box-shadow: 0 4px 5px rgba(43, 7, 21, 0.5);
}
.findC-bottom > div {
  flex: 0 0 50%;
}
.routerC {
  padding: 40px;
  box-sizing: border-box;
}
.routertitle {
  font-size: 18px;
  font-family: Regular;
  color: #e60012;
}
.routerspan1 {
  display: inline-block;
  padding: 0 20px;
}
.routerspan2 {
  padding: 0 40px;
  font-family: Light;
}
.call-c {
  display: none;
}
.call-c {
  width: 100%;
  position: fixed;
  bottom: 0;
}
.erweima {
  display: block;
  margin: auto;
  width: 50%;
}
// 底部导航
.call {
  z-index: 999;
  position: fixed;
  right: 10px;
  top: 33%;
}
.call img {
  width: 60px;
  height: 60px;
  display: block;
  margin: 5px 0;
}
.call > div {
  position: relative;
}
.callphones {
  display: none;
}
.callqr,
.callphone,
.callmail,
.callzixun {
  position: relative;
}
.callphone > img:nth-child(2),
.callmail > img:nth-child(2),
.callzixun > img:nth-child(2) {
  position: absolute;
  display: none;
}
.callqr > img:nth-child(2) {
  display: none;
  position: absolute;
  top: -35px;
  left: -130px;
  width: 130px;
  height: 120px;
}
.call .callphones {
  display: none;
  position: absolute;
  top: -4px;
  left: -168px;
  width: 166px;
  height: 60px;
}
.call .callzixuns {
  display: none;
  position: absolute;
  top: -4px;
  left: -178px;
  width: 176px;
  height: 60px;
}
.call .callmails {
  display: none;
  position: absolute;
  top: -4px;
  left: -210px;
  width: 206px;
  height: 60px;
}
.call .J {
  display: none;
}
.callqr:hover .qrcode,
.callphone:hover .callphones,
.callmail:hover .callmails,
.callzixun:hover .callzixuns {
  display: block;
}
@media (max-width: 1700px) {
  .exchange {
    right: 2rem;
  }
}
@media (max-width: 1074px) {
  .services div:nth-child(1) {
    margin: 15px 23%;
  }
  .services div:nth-child(2) {
    text-align: center;
  }
}
@media (max-width: 1430px) {
  .route {
    text-align: center;
    width: 80%;
    margin: auto;
  }
}
@media (max-width: 1182px) {
  .navList li {
    font-size: 13px;
    margin: 0 15px 15px !important;
  }
  .index .navC {
    width: 90%;
  }
}
@media (max-width: 890px) {
  .index .navC {
    width: 95%;
    text-align: center;
    height: auto;
    line-height: 0;
  }
  .index .navC > ul {
    float: none;
    margin-right: 0px;
  }
  .index .navC > div {
    margin-left: 0;
  }
  .index .navC > ul > li {
    padding: 0 2px;
    font-size: 12px;
    box-sizing: border-box;
  }
  .index .bannerTitle {
    font-size: 30px;
  }
  .index .bannerCC {
    width: 95%;
    margin: auto;
  }
  .index .exchange {
    width: 95%;
    height: 254px;
  }
  .index .bannerC {
    height: 400px;
  }
  .index .el-radio-group {
    float: none;
    width: 100%;
    text-align: center;
  }
  .el-dialog__wrapper.er .el-dialog {
    position: fixed;
    bottom: 40px;
  }
  .call-c {
    background: #64a70b;
    display: flex;
    flex-wrap: nowrap;
    height: 40px;
    line-height: 40px;
  }
  .call-c > div {
    text-align: center;
    flex: 1 0 49%;
    color: white;
  }
  .call-c > div:nth-child(1) {
    border-right: 1px solid #eee;
  }
  .call-c a {
    text-decoration: none;
    color: white;
  }
  .zixun button {
    width: 60%;
  }
  .good {
    width: 100%;
  }
  .index .GlobalC-bottom {
    display: none;
  }
  .index .GlobalC-bottom-mo {
    height: 260px;
    display: block;
  }
  .GlobalC-bottom-mo ul {
    display: none;
  }
  .index .GlobalC-bottom-mo .el-carousel__container {
    height: 260px !important;
  }
  .index .zxrx {
    margin: 20px 0;
  }
  .index .light {
    position: relative;
  }
  .index .light span {
    position: absolute;
    display: block;
    bottom: 5%;
    left: 40%;
    z-index: 999;
    color: white;
  }
  .b1 {
    display: none;
  }
  .block {
    display: none;
  }
  header {
    position: relative;
  }
  .el-carousel__container {
    height: 10rem !important;
  }
  .label {
    right: 0;
  }
  .label:hover {
    right: 3rem;
  }
  .index .content {
    width: 95%;
  }
  .content h1,
  .query h1,
  .service h1,
  .find h1 {
    text-align: center;
    width: 70%;
    margin: auto;
    font-weight: bold;
    font-size: 1.5rem;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .nowL,
  .nowR {
    float: none;
    width: 100%;
  }
  .nowR table {
    margin-left: 0;
  }
  .nowL table,
  .nowR table {
    width: 100%;
  }
  .table-div td {
    width: auto !important;
  }
  .table-div div {
    height: 100%;
    float: left;
  }
  .m1 {
    margin: 0;
  }
  .table-div div span {
    width: auto;
    font-size: 13px;
  }
  .query {
    width: 100%;
    margin: auto;
    background: white;
  }
  .query-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem 0;
    justify-content: space-around;
  }
  .Reference {
    width: 100%;
  }
  .Reference_content {
    display: flex;
    justify-content: space-around;
    height: 40px;
  }
  .Reference_content div {
    margin: 0;
    font-size: 0.6rem;
    width: 26%;
    height: 40px;
  }
  .Reference_content div span {
    text-indent: 0;
    display: inline-block;
    font-size: 0.6rem;
  }
  .Reference > button {
    width: 88%;
    height: 50px;
    font-size: 0.8rem;
  }
  .find {
    width: 100%;
    margin: 0 auto 100px auto;
  }
  .service {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .service h1 {
    width: 100%;
    margin-bottom: 20px;
  }

  .service div {
    margin: 0 auto C;
    float: none !important;
  }
  .services {
    width: 100%;
  }
  .services p {
    text-align: center;
  }
  /*地图提示*/
  .route {
    width: 100% !important;
  }
  .route p:nth-child(1) {
    text-align: center;
  }
  .route > div > p:last-child {
    font-size: 13px !important;
  }
  footer span {
    font-size: 12px;
  }
  .el-form {
    width: 100% !important;
    margin: auto !important;
  }
  nav {
    overflow: hidden;
    height: auto;
  }
  .logo {
    margin-left: 5%;
    float: left;
  }
  .navList {
    width: 100%;
    height: 56%;
  }
  .navList li {
    text-align: center;
    margin: 0 10px;
    display: block;
  }
  nav .el-icon-s-operation {
    font-size: 2rem;
    margin-top: 1.5rem;
    display: block;
    float: right;
  }
  nav .el-icon-s-unfold {
    display: block;
    font-size: 1.5rem;
    margin-top: 0.8rem;
    color: #d4ae7f;
  }
  nav .el-unfold {
    display: none;
    position: absolute;
    font-size: 1rem;
    top: 45%;
    right: -1.5rem;
    width: 3rem;
    height: 3rem;
    text-align: center;
    border-radius: 50%;
    background: black;
  }
  .fold {
    transition: all 0.8s ease-in-out;
    background: white;
    position: fixed;
    z-index: 20;
    width: 60%;
    height: 100%;
    top: 0;
    left: -60%;
  }
  .prompt {
    position: absolute;
    bottom: 0;
    display: block;
    float: left;
    width: 100%;
    text-align: center;
    font-size: 0.7rem;
  }
  .prompt .iconfont {
    text-align: center;
    display: block;
  }
  .index .queryC-bottom {
    display: none;
  }
  .index .queryC-bottom-mo {
    display: block;
  }
  .index .queryC-bottom-mo > div {
    width: 100%;
    height: 100px;
    overflow: hidden;
    background: #f7f7f7;
    box-shadow: 0 1px 3px 0px rgba(64, 64, 64, 0.16);
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .index .queryC-bottom-mo > div > div:nth-child(1) {
    flex: 0 0 29%;
    height: 100px;
    background-size: 50% 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .index .queryC-bottom-mo > div > div:nth-child(2) {
    flex: 0 0 71%;
    box-sizing: border-box;
    padding: 12px 10px 10px 0px;
  }
  .index .queryC-bottom-mo > div > div:nth-child(2) > div {
    text-align: center;
    font-family: Regular;
  }
  .index .queryC-bottom-mo > div > div:nth-child(2) > span {
    text-align: center;
    color: #757575;
    font-family: Light;
    font-size: 12px;
  }
  .index .queryC-bottom-mo > div:nth-child(1) > div:nth-child(1) {
    background-image: url("../assets/images/process_content_icon1.png");
  }
  .index .queryC-bottom-mo > div:nth-child(2) > div:nth-child(1) {
    background-image: url("../assets/images/process_content_icon2.png");
  }
  .index .queryC-bottom-mo > div:nth-child(3) > div:nth-child(1) {
    background-image: url("../assets/images/process_content_icon3.png");
  }
  .index .queryC-bottom-mo > div:nth-child(4) > div:nth-child(1) {
    background-image: url("../assets/images/process_content_icon4.png");
  }
  .index .queryC-bottom-mo > div:nth-child(5) > div:nth-child(1) {
    background-image: url("../assets/images/process_content_icon5.png");
  }
}
</style>
<style>
.index .el-button.databtn.el-button--primary {
  background: #e60012;
  border: 1px solid #e60012;
}
.index .navList .el-button.el-button--text:hover span {
  color: black;
}
.index .el-select {
  width: 100%;
}
.index .el-button.navbtn.el-button--default.el-button--mini:hover {
  color: white;
  background-color: red;
}
.index .routerC > div:nth-child(1) > p:nth-child(1) {
  font-family: Regular;
  font-size: 24px;
}
.index .routerC > div:nth-child(1) > p:nth-child(2) {
  font-family: Regular;
  font-size: 18px;
}
.index .routerC > div:nth-child(1) > p {
  font-family: Regular;
  font-size: 18px;
}
@media (max-width: 1200px) {
  .goodC,
  .GlobalC,
  .queryC,
  .serviceC,
  .findC {
    width: 85%;
  }
  .routerC {
    padding: 20px;
  }
  .ser-c > div:nth-child(2) > div:nth-child(2) > div:nth-child(3) {
    width: 80%;
  }
  .exchange {
    width: 90%;
  }
}
@media (max-width: 890px) {
  .index .nav {
    height: 140px;
  }
  .index .bannerC {
    margin-top: 140px;
  }
  .index .el-tabs__nav {
    width: 100%;
    height: 56px;
  }
  .index .el-tabs--top .el-tabs__item.is-top:nth-child(2),
  .index .el-tabs--top .el-tabs__item.is-top:nth-child(3) {
    padding-left: 0;
    height: 56px;
    line-height: 56px;
    font-size: 16px;
  }
  .index .ex-bottom {
    width: 100%;
  }
  .index .content {
    width: 90%;
    margin: 3% auto;
  }
  .index .contentTltle {
    font-size: 20px;
    width: 100%;
    height: 30px;
    line-height: 30px;
  }
  .index .net-money p,
  .now-money p {
    margin: 10px 0 0 0;
    font-size: 12px;
  }
  .index .net-money > p > span,
  .now-money > p > span {
    font-size: 12px;
  }
  .index .net-money table tr,
  .index .now-money table tr {
    font-size: 12px;
  }
  .index .MoreE {
    margin: 30px 0 0 0;
  }
  .index .MoreE > div:nth-child(1),
  .index .MoreE > div:nth-child(3) {
    width: 25%;
  }
  .index .MoreE > div:nth-child(2) {
    width: 50%;
  }
  .index .MoreE > div:nth-child(2) > button {
    font-size: 12px;
  }
  .index .goodC-top > div,
  .GlobalC-top > div,
  .queryC-top > div,
  .serviceC-top > div,
  .findC-top > div {
    font-size: 20px;
  }
  .index .goodC-top > span,
  .GlobalC-top > span,
  .queryC-top > span,
  .serviceC-top > span,
  .findC-top > span {
    font-size: 12px;
  }
  .index .goodC,
  .GlobalC,
  .queryC,
  .serviceC {
    overflow: hidden;
    width: 90%;
  }
  .index .findC {
    width: 95%;
  }
  .index .goodC-bottom > div {
    flex: 0 0 50%;
  }
  .index .goodC-bottom > div > div {
    float: none;
    width: 100%;
    text-align: center;
  }
  .index .text > div:nth-child(1) {
    font-size: 12px;
  }
  .index .text > div:nth-child(3) > span {
    font-size: 12px;
  }
  .index .GlobalC-bottom > div > div > span {
    font-size: 12px;
    bottom: 35px;
  }
  .index .cicletu img {
    width: 50px;
    height: 50px;
  }
  .index .cicle > div:nth-child(2) {
    left: 5.5%;
  }
  .index .cicle > div:nth-child(3) {
    left: 26%;
  }
  .index .cicle > div:nth-child(4) {
    left: 46%;
  }
  .index .cicle > div:nth-child(5) {
    left: 66%;
  }
  .index .cicle > div:nth-child(6) {
    left: 86%;
  }
  .index .cicletext > div > div,
  .index .cicletext > div > span {
    font-size: 12px;
  }
  .index .serviceC-bottom > div {
    flex: 0 0 100%;
  }
  .index .ser-c > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) {
    font-size: 12px;
  }
  .index .ser-c > div:nth-child(2) > div:nth-child(2) > div:nth-child(3) {
    font-size: 12px;
  }
  .index .findC-bottom > div {
    flex: 0 0 100%;
  }
  .index .routerC > div:nth-child(1) > p:nth-child(1) {
    font-size: 20px;
  }
  .index .routerC > div:nth-child(1) > p {
    font-size: 12px;
  }
  .index .routerC {
    padding: 10px;
  }
  .index .routertitle {
    font-size: 12px;
  }
  .index .routerspan2 {
    padding: 0px;
    font-size: 12px;
  }
  .index .call {
    display: none;
  }
  .goodC,
  .GlobalC,
  .queryC,
  .serviceC,
  .findC {
    width: 95%;
  }
  .ser-c {
    height: auto;
  }
  .findC-bottom {
    height: auto;
  }
  .bannerCC {
    width: 90%;
  }
}
@media (max-width: 890px) {
  .index .navC > div {
    float: none;
  }
  .mmap {
    height: 500px;
    width: 100%;
  }
}
@media (max-width: 890px) {
  .queryC {
    width: 60% !important;
  }
}
@media (max-width: 690px) {
  .queryC {
    width: 75% !important;
  }
}
</style>
