<template>
  <div class="cmarkets4">
      <v-nav></v-nav>
      <img src="../assets/images/banner1.jpg" alt="">
      <div class="center">
          <div class="content">
            <h2>美元强势尚在，中国经济走软，人民币汇率再次破七</h2>
            <p>摘要：华盛顿 — 人民币对美元的汇率星期四（5月18日）破七，达到一美元兑7.02元人民币。这也是人民币对美元汇率五个多月来的新低。1:7是人民币对美元汇率的心理大关，人民币对美元汇率星期三盘中首次破七，星期四尾盘一度甚至跌到7.05。</p>
            <p>路透社引述专家的话说，人民币贬值当然反映出中国经济的疲软，但是美联储的政策决定，可能是影响人民币走低更重要的因素。另有中国财经专家指出，美联储表态年内降息的可能性很小，这一表态带动近期美元指数有所反弹，与此同时，人民币兑美元出现了一定幅度的贬值。</p>
            <p>人民币兑美元的汇率上一次贬破七字大关还是在去年11月，中国新冠疫情严厉封控措施解封之前。不过去年12月初中国政府突然决定防疫解封之后人们对中国经济强劲复苏有过短暂的乐观。这种乐观情绪带动人民币汇率的回升。</p>
            <p>路透社报道说，近期中国公布的经济数据显示，经济复苏力度远低于预期，令人失望，不少外国资金也开始离开中国市场。自今年一月下旬以来，人民币兑美元的汇率已经下跌了4%。</p>
            <p>路透社引述野村证券（Nomura）和法国兴业银行（Societe Generale）分析师的话说，人民币兑美元的汇率有可能进一步走软，达到7.3。法国兴业银行驻香港亚洲宏观策略师Kiyong Seong认为，人民币汇率走低是中美货币政策上的分歧以及中国经济增长乏力的结果。</p>
            <p>“推动美元兑人民币汇率过去一个月来爬升的一个重要因素应该与美元有关，所以这不仅仅是一个人民币故事，”加拿大皇家银行资本市场公司亚洲货币策略部门主管Alvin Tan对路透社说。</p>
            <p>路透社说，由中国央行管理的中国外汇交易中心（CFETS）贸易加权货币指数从今年二月的100已经降至99。</p>
            <p>与此同时，美联储自2022年3月以来已经加息十次，基准利率已经提升至5%以上。由于通胀数据时好时坏，美联储尚未最后决定这次持续一年多的强加息周期是否应该结束。而中国经济走软迹象的增加也给人民币币值下行增添了新的压力。</p>
            <p>路透社引述一位不愿具名的上海出口商的话说，他现在还在观望，希望暂时将美元握在手中，而不是立即将美元兑换成人民币。</p>
            <p>“我知道我不应该太贪婪，但是人民币将贬到7.3。我还要等一等，”这位出口商对路透社说。</p>
            <p>中国人民银行迄今为止并没有对最近人民币汇率的走势表现出不安的迹象，或采取措施干预人民币汇率。但是加拿大皇家银行资本市场公司亚洲货币策略部门主管Alvin Tan认为中国当局会敏感地关注人民币是否遭到抛售。</p>
            <p>“所以即使人民币走弱，他们更重视汇市秩序井然。坦率说，到目前为止汇市总的来说秩序井然，”Alvin Tan对路透社说。</p>
            <p>渣打银行中国宏观策略部主管刘洁认为，人民币汇率近期内还将继续贬值。</p>
            <p>“利息的差距仍然很大，许多对冲基金持续将人民币用作运营资金，”刘洁说。</p>
            <p>“除了利差交易，另一个季节性的因素就是股息支付季节即将来临。所以从短期看，我们不认为人民币汇率有很大的上扬空间。相反，我们认为它将继续面临下行压力，”刘洁对路透社说。</p>
        </div>
      </div>
      <!-- prompt -->
      <div class="p">
          <p>免责声明：文章来至网络，文章表达观点不代表本站观点，文章版权属于原作者所有，若有侵权，请联系本站站长处理！</p>
      </div>
      <v-footer></v-footer>
  </div>
</template>

<script>
import Nav from './nav'
import Footer from './footer'
export default {
  name: 'cmarkets4',
  data () {
    return {

    }
  },
  components: {
    'v-nav': Nav,
    'v-footer': Footer
  }
}
</script>

<style scpped>
    .cmarkets4{background: #eee;}
    .cmarkets4>div:nth-child(1){background: white;}
    .center{
        width: 85%;
        margin: 2% auto;
    }
    .center .content{
        width: 70%;
        margin: auto;
    }
    .cmarkets4 .content p{
        margin: 2% 0;
    }
    h2{text-align: center;}
    .p{text-align: center;padding: 3% 0;background: white;}
</style>
<style>

</style>
