<template>
  <div class="footer">
    <footer>
      <div class="footer-left">
        <div>
          <img style="max-width:119px;" src="https://trustpass.oss-cn-hongkong.aliyuncs.com/images/TPE_LOGO1_%E6%A8%AA%E7%89%88.png" alt="">
          <!-- <img src="../assets/images/bottom_logo.png" alt="" /> -->
        </div>
        <ul>
          <li>
            <span
              ><img src="../assets/images/bottom_contact1_icon.png" alt=""
            /></span>
            <span class="bottomSpan">（上環總店）聯係方式 : (852) 3462 6432</span>
          </li>
          <li>
            <span
              ><img src="../assets/images/bottom_contact4_icon.png" alt=""
            /></span>
            <span class="bottomSpan">傳真(FAX) : (852) 34604910 </span>
          </li>
          <li>
            <span
              ><img src="../assets/images/bottom_contact3_icon.png" alt=""
            /></span>
            <span class="bottomSpan"
              >（上環總店）地址(ADDRESS) : 上環德輔道中254號金融商業大廈地下A舖
            </span>
          </li>
        </ul>
        <div class="link" style="display:none">
          <div class="link-c">
            <ul>
              <li>友情連接：</li>
              <li>
                <a href="http://www.dhwaibi.com/" target="_blank">兑换外币</a>
              </li>
              <!-- <li><a href="http://www.gdhfqp.com" target="_blank">广州月嫂培训</a></li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <ul>
          <li>我們的服務</li>
          <li>兌換資訊</li>
          <li>聯繫我們</li>
        </ul>
      </div>
      <div class="footer-bottom">
        Copyright ©2019誠通找換外幣兌換 siteMap
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {}
};
</script>
<style scoped>
@import "../common/font/font.css";
.footer {
  background-image: url("../assets/images/bottom_bg.png");
  background-size: 100% 100%;
  width: 100%;
  height: 410px;
}
.footer footer {
  width: 75%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px 0px;
  box-sizing: border-box;
}
.footer footer > div:nth-child(1) {
  flex: 0 0 45%;
}
.footer footer > div:nth-child(2) {
  flex: 0 0 45%;
}
.footer footer > div:nth-child(3) {
  flex: 0 0 100%;
}
/* .footer-left,.footer-right{
  float: left;
  margin: 50px 0 0 0;
} */
.footer-right {
  font-family: Regular;
  color: white;
}
.footer-left {
  width: 50%;
  box-sizing: border-box;
  padding: 0 40px;
}
.footer-right {
  width: 50%;
}
.footer-left li {
  margin: 20px 0;
  color: white;
  font-family: Regular;
}
.footer-right li {
  display: inline-block;
  padding: 0 20px;
  font-size: 16px;
}
.bottomSpan {
  padding: 0 10px;
}
.footer-bottom {
  width: 100%;
  color: white;
  position: absolute;
  bottom: 10px;
  text-align: center;
  font-family: Regular;
}
.footer-left .link-c li {
  display: inline;
  color: #97969596;
  font-family: Regular;
}
.footer-left .link-c li a {
  color: #97969596;
  font-family: Regular;
  font-size: 14px;
}
@media (max-width: 866px) {
  .footer {
    margin: 0 0 10px 0;
  }
  .footer footer {
    width: 100%;
  }
  .footer-left,
  .footer-right {
    margin: 10px 0 0 0;
  }
  .footer-left {
    padding: 0;
  }
  .footer-right li {
    font-size: 12px;
  }
  .footer .link li {
    font-size: 12px;
  }
}
@media (max-width: 650px) {
  .footer footer > div:nth-child(1) {
    flex: 0 0 100%;
    padding: 0 20px;
  }
  .footer footer > div:nth-child(2) {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
  .footer {
    height: auto;
  }
}
</style>
