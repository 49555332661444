<template>
  <div class="cmarkets1">
      <v-nav></v-nav>
      <img src="../assets/images/banner1.jpg" alt="">
      <div class="center">
          <div class="content">
            <h2>中國跨境交易中人民幣使用率首次超過美元 離挑戰「美元霸權」有多遠？</h2>
            <p>摘要：中國外匯管理局近期公布， 在今年3月的中國跨境收支中，用人民幣交易的佔比達到48%，用美元的佔比47%，這是人民幣首次在該國跨境交易中的使用率超過美元。</p>
            <h3>2009年7月，中國政府在廣州、深圳等城市開展跨境貿易人民幣結算試點，標誌著人民幣國際化的開端。當時，人民幣在中國跨境收支中佔比接近於零，美元的佔比則為83%。</h3>
            <p>近年來，越來越多的國家與中國達成協議，在雙邊貿易中用人民幣進行結算。尤其是去年以來美聯儲大幅加息，使這一進程加速。</p>
            <h3>原因</h3>
            <p>背後的重要原因是，中國政府的推波助瀾，把人民幣雙邊結算作為重要的外交成果。</p>
            <p>比如，3月29日，中國和巴西宣佈達成新的協議——雙方貿易以本國貨幣結算，不再使用美元作為中介。</p>
            <p>巴西總統盧拉在隨後訪華時表示：「我每到晚上都會問自己，為什麼這些國家都必須要以美元作為結算方式，為什麼不能用自己國家的貨幣作為國際結算貨幣？這到底是誰決定的？為什麼不是人民幣？為什麼不是巴西雷亞爾？我知道大家比較不習慣，因為大家長久以來使用美元已經成了一個習慣，但我想，21世紀我們應該可以做一點非常不一樣的事情。」</p>
            <p>再比如，4月26日，阿根廷政府宣佈將使用人民幣替代美元，來支付從中國進口的商品。</p>
            <p>還有，俄羅斯、伊朗等因為美國制裁，而被迫使用人民幣結算的國家。</p>
            <p>俄烏戰爭後，美國的金融制裁使俄羅斯公司無法通過國際資金清算系統（SWIFT）結算，不得不通過中國央行打造的人民幣跨境支付系統（CIPS），使用人民幣結算。根據俄羅斯公布的消息，人民幣在俄外匯交易總量中所佔份額達到近40%，而在戰前，人民幣只佔0.32%。</p>
            <p>還有一些國家，由於在中國的「一帶一路」倡議下，與中國合作建設大量基礎設施項目，這些項目的融資大多由中國提供，因此創造了大量以人民幣計價的資產。比如，4月中旬，孟加拉國和俄羅斯商定同意使用人民幣來支付俄羅斯在該國建設的核電站項目款項。</p>
            <h3>差距</h3>
            <p>不過，從全球金融體系中看人民幣的國際化程度，則弱得多。</p>
            <p>目前，人民幣是第五大國際儲備貨幣、第五大支付貨幣。</p>
            <p>根據國際貨幣基金組織（IMF）公布的數據，截至去年底，美元在全球外匯儲備中佔比為58%，之後依次是歐元（20%）、日元（5.5%）、英鎊（4.9%），人民幣只能排到第五，佔比為2.7%。</p>
            <p>即便像巴西這樣人民幣佔其儲備貨幣比例達到第二多的國家，也僅有5.37%，不及美元的80.42%。</p>
            <p>相比儲備功能，在支付功能上，人民幣則差不多，依然排到第五。根據SWIFT數據顯示，今年2月人民幣國際支付份額為2.19%，落後於美元（41%）、歐元（36%）、英鎊（6.58%）、日元（2.98%）。</p>
          </div>
      </div>
       <!-- prompt -->
      <div class="p">
          <p>免责声明：文章来至网络，文章表达观点不代表本站观点，文章版权属于原作者所有，若有侵权，请联系本站站长处理！</p>
      </div>
      <v-footer></v-footer>
  </div>
</template>

<script>
import Nav from './nav'
import Footer from './footer'
export default {
  name: 'cmarkets1',
  data () {
    return {

    }
  },
  components: {
    'v-nav': Nav,
    'v-footer': Footer
  }
}
</script>

<style scpped>
    .cmarkets1{background: #eee;}
    .cmarkets1>div:nth-child(1){background: white;}
    .center{
        width: 85%;
        margin: 2% auto;
    }
    .center .content{
        width: 70%;
        margin: auto;
    }
    .cmarkets1 .content p{
        margin: 2% 0;
    }
    h2{text-align: center;}
    .p{text-align: center;padding: 3% 0;background: white;}
</style>
<style>

</style>
