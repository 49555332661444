import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/components/index'
import ExchangeTable from '@/components/exchangeTable'
import HuiTong from '@/components/HuiTong'
import ChengTong from '@/components/ChengTong'
import AllExchangTable from '@/components/AllExchangTable'
// import bank from '@/components/bank'
import error from '@/components/error'
import information from '@/components/information'
import cmarkets1 from '@/components/cmarkets1'
import cmarkets2 from '@/components/cmarkets2'
import cmarkets3 from '@/components/cmarkets3'
import cmarkets4 from '@/components/cmarkets4'
import cmarkets5 from '@/components/cmarkets5'
import cmarkets6 from '@/components/cmarkets6'
import cmarkets7 from '@/components/cmarkets7'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
      meta: {
        title: '外幣兌換 | 人民幣兌換外幣 | 外幣換人民幣「誠通找換」',
        description: '誠通找換主營港幣、人民幣、日元、美元及其他各國貨幣.支持全球兌換,人民幣兌換外幣、中港澳及世界各地匯款,現金交易.誠通找換希望在跨境任何業務都能夠幫到妳.',
        keywords: '外幣兌換,人民幣兌換外幣,跨境匯款,各地匯款,兌換外幣,各國貨幣,誠通找換'
      }
    },
    {
      path: '/exchangeTable/',
      name: 'ecchangeTable',
      component: ExchangeTable
    },
    {
      path: '/HuiTong',
      name: 'HuiTong',
      component: HuiTong
    },
    {
      path: '/ChengTong',
      name: 'ChengTong',
      component: ChengTong
    },
    {
      path: '/AllExchangTable/',
      name: 'AllExchangTable',
      component: AllExchangTable
    },
    {
      path: '/error/',
      name: 'error',
      component: error,
      meta: {
        title: '这个.. 页面没有找到！！！_SJ5D.COM',
        description: '',
        keywords: ''
      }
    },
    {
      path: '/information/',
      name: 'information',
      component: information,
      meta: {
        title: '兑换资讯 -誠通找換',
        description: '誠通找換助你香港開戶。提供全球匯款業務、匯率查詢、全球兌換、跨境服務以及免費跨境服務咨詢。实时更新兑换资讯...',
        keywords: '兑换资讯'
      }
    },
    {
      path: '/cmarkets1/',
      name: 'cmarkets1',
      component: cmarkets1,
      meta: {
        title: '中國跨境交易中人民幣使用率首次超過美元 離挑戰「美元霸權」有多遠？ -誠通找換',
        description: '摘要：中國外匯管理局近期公布， 在今年3月的中國跨境收支中，用人民幣交易的佔比達到48%，用美元的佔比47%，這是人民幣首次在該國跨境交易中的使用率超過美元...',
        keywords: '中國跨境交易中人民幣使用率首次超過美元 離挑戰「美元霸權」有多遠？'
      }
    },
    {
      path: '/cmarkets2/',
      name: 'cmarkets2',
      component: cmarkets2,
      meta: {
        title: '中國雙邊結算中人民幣佔比首超美元 -誠通找換',
        description: '提要：根據中國國家外匯管理局的統計數據，《日本經濟新聞》7月25日按照結算貨幣進行分類，對企業、個人和投資者的跨境交易進行統計。統計數據顯示，2023年第二季度，人民幣在中國跨境交易中佔比達49%，首次超過美元。這主要是因為中國資本市場更加開放，中俄採用兩國人民幣結算的貿易增多...',
        keywords: '中國雙邊結算中人民幣佔比首超美元'
      }
    },
    {
      path: '/cmarkets3/',
      name: 'cmarkets3',
      component: cmarkets3,
      meta: {
        title: '中國一個月內連續降低美元存款利率 以阻人民幣繼續貶值 -誠通找換',
        description: '提要：中國主要國有銀行在一個月內連續第二次降低美元存款利率，以阻止人民幣貶值進一步下滑...',
        keywords: '中國一個月內連續降低美元存款利率 以阻人民幣繼續貶值'
      }
    },
    {
      path: '/cmarkets4/',
      name: 'cmarkets4',
      component: cmarkets4,
      meta: {
        title: '美元强势尚在，中国经济走软，人民币汇率再次破七 -誠通找換',
        description: '摘要：华盛顿 — 人民币对美元的汇率星期四（5月18日）破七，达到一美元兑7.02元人民币。这也是人民币对美元汇率五个多月来的新低。1:7是人民币对美元汇率的心理大关，人民币对美元汇率星期三盘中首次破七，星期四尾盘一度甚至跌到7.05...',
        keywords: '美元强势尚在，中国经济走软，人民币汇率再次破七'
      }
    },
    {
      path: '/cmarkets5/',
      name: 'cmarkets5',
      component: cmarkets5,
      meta: {
        title: '人民幣急跌確認中國挑戰美元敗陣　分析：國際市場寧沽人仔要美金！ -誠通找換',
        description: '摘要：人民幣在岸及離岸價均創近7個月新低，當中離岸人民幣更一度大跌400基點。雖然中共一直高調將人民幣國際化，聯合部分發展中國家挑戰美元「霸權」地位，但最終敵不過市場力量。...',
        keywords: '人民幣急跌確認中國挑戰美元敗陣　分析：國際市場寧沽人仔要美金'
      }
    },
    {
      path: '/cmarkets6/',
      name: 'cmarkets6',
      component: cmarkets6,
      meta: {
        title: '英镑：欧盟携手英国击碎市场期待的让步美梦，本周脱欧重点在这里！ -誠通找換',
        description: '摘要：英国8月GDP出现收缩，但3个月GDP月率增长超出了预期；英国脱欧谈判正在进行，英国首相约翰逊将会见爱尔兰总理瓦拉德卡...',
        keywords: '欧盟携手英国'
      }
    },
    {
      path: '/cmarkets7/',
      name: 'cmarkets7',
      component: cmarkets7,
      meta: {
        title: '数据快评：美国9月CPI报告显示月率0增长创下7个月新低，了解报告细节！ -誠通找換',
        description: '摘要：尽管美国9月CPI报告显示CPI年率与前值持平，但月率显示0增长并创下7个月新低。10 月10日周四公布的数据显示，美国9月消费者物价指数（CPI）月率持平，并且创下了7个月新低！而年率录得1.7%，低于预期的1.9%；剔除食品及能源的核心CPI月率上升0.1%，年率录得2.4%、符合预期的2.4%。如何利用重大消息来交易？...',
        keywords: '美国9月CPI报告'
      }
    },
    {
      path: '*',
      redirect: '/error/'
    }
  ]
})
