<template>
  <div class="cmarkets6">
      <v-nav></v-nav>
      <img src="../assets/images/banner1.jpg" alt="">
      <div class="center">
          <div class="content">
            <h2>英镑：欧盟携手英国击碎市场期待的让步美梦，本周脱欧重点在这里！</h2>
            <p>摘要：英国8月GDP出现收缩，但3个月GDP月率增长超出了预期；英国脱欧谈判正在进行，英国首相约翰逊将会见爱尔兰总理瓦拉德卡</p>
            <p>英国国家统计局(ONS)的最新数据显示，英国8月的经济增速出现收缩，但3个月GDP月率增速超出市场预期。8月GDP月率与持平的7月相比下降了0.1%，而3个月GDP月率与预期相比上升了0.3%，前值为0.1%。根据国家统计局的数据，这一积极表现得益于电视和电影的产出提振了服务业，而制造业的表现依然疲软。3个月服务业指数上升0.4%，好于预期，前值为0.2%</p>
            <p>在今天英国首相约翰逊和爱尔兰总理瓦拉德卡于中午举行的脱欧会议之前，英镑/美元小幅走高，但随后回吐涨幅。欧盟成员国和官员一直表示，本周末是关键时刻，要在10月17日至18日欧盟成员国举行会议之前，看看是否有达成协议的基础。约翰逊的最新提议并没有得到欧盟的任何支持，而昨天关于欧盟有时间限制的北爱尔兰后备方案提议的传闻已被欧盟驳斥，并被英国拒绝。如何利用重大消息来交易？
            <p>现在查看最新的IG客户情绪来帮助您了解IG散户的美元指数、欧元/美元、英镑/美元以及更大股指的持仓变化，进一步获得主要货币对和原油、黄金还有各大股指的走势指引</p>
            <p>在DailyFX，我们研究IG实时交易超过3000万次，发现交易者犯了我们的《成功交易者的特征》报告中第一个错误。了解哪里出了问题，并做出必要的调整是像专业人士那样交易的第一步。现在下载《成功者交易手册》</p>
        </div>
      </div>
      <!-- prompt -->
      <div class="p">
          <p>免责声明：文章来至网络，文章表达观点不代表本站观点，文章版权属于原作者所有，若有侵权，请联系本站站长处理！</p>
      </div>
      <v-footer></v-footer>
  </div>
</template>

<script>
import Nav from './nav'
import Footer from './footer'
export default {
  name: 'cmarkets6',
  data () {
    return {

    }
  },
  components: {
    'v-nav': Nav,
    'v-footer': Footer
  }
}
</script>

<style scpped>
    .cmarkets6{background: #eee;}
    .cmarkets6>div:nth-child(1){background: white;}
    .center{
        width: 85%;
        margin: 2% auto;
    }
    .center .content{
        width: 70%;
        margin: auto;
    }
    .cmarkets6 .content p{
        margin: 2% 0;
    }
    h2{text-align: center;}
    .p{text-align: center;padding: 3% 0;background: white;}
</style>
<style>

</style>
