<template>
  <div class="exchange">
    <el-tabs @tab-click="handleClick" v-model="activeName">
      <el-tab-pane label="現金價" name="first">
        <span slot="label">
          <img
            src="../assets/images/banner_table_icon1_select.png"
            style="position: relative;
    top: -3px;"
            alt
          />
          <span style="padding: 0 20px">現金價</span>
        </span>
        <div class="ex-bottom">
          <div class="have">
            <p>
              <span>{{$t('message.exchange.title1')}}</span>
            </p>
            <div class="haveC">
              <input
                type="text"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="booking_note.fromAmount"
              />
              <select v-model="booking_note.fromCurrency">
                <option v-for="note in note_buy" :key="note.value">{{note}}</option>
              </select>
            </div>
          </div>
          <div>
            <img src="../assets/images/banner_table_switch.png" alt />
          </div>
          <div class="get">
            <p>
              <span>{{$t('message.exchange.title2')}}</span>
            </p>
            <div class="haveC">
              <input
                type="text"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="booking_note.toAmount"
              />
              <select v-model="booking_note.toCurrency">
                <option v-for="note in note_buy" :key="note.id">{{note}}</option>
              </select>
            </div>
          </div>
          <div>
            <span style="font-size:13px">
              <span>
                {{$t('message.exchange.huilv')}}：1
                <span class="base"></span>=
                <span class="rate1"></span>
                <span class="code"></span>&nbsp;&nbsp;&nbsp;&nbsp;(1
                <span class="code"></span>=
                <span class="rate2"></span>
                <span class="base"></span>)
              </span>
            </span>
          </div>
          <div class="reserver-btn">
            <el-button
              type="text"
              @click="dialogFormVisible = true"
            >{{$t('message.exchange.button')}}</el-button>
            <el-dialog title="網上預約" :visible.sync="dialogFormVisible">
              <el-form :model="booking_note" :rules="rules" ref="booking_note">
                <div class="reserveC">
                  <el-form-item label="交易類型:" :label-width="formLabelWidth">
                    <el-select v-model="booking_note.typeNmae" placeholder="請選擇交易類型">
                      <el-option
                        :label="exT"
                        v-for="exT in exchangeType"
                        :key="exT.value"
                        :value="exT"
                      >{{exT}}</el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="交易貨幣:" :label-width="formLabelWidth">
                    <el-select v-model="booking_note.fromCurrency" placeholder="請選擇需要兌換的貨幣">
                      <el-option
                        :label="note"
                        v-for="note in note_buy"
                        :key="note.value"
                        :value="note"
                      >{{note}}</el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="兌換貨幣:" :label-width="formLabelWidth">
                    <el-select v-model="booking_note.toCurrency" placeholder="請選擇需要的貨幣">
                      <el-option
                        :label="note"
                        v-for="note in note_buy"
                        :key="note.value"
                        :value="note"
                      >{{note}}</el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="姓名:" :label-width="formLabelWidth" prop="ContactPerson">
                    <el-input v-model="booking_note.ContactPerson" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="郵箱：" :label-width="formLabelWidth" prop="ContactEmail">
                    <el-input v-model="booking_note.ContactEmail" autocomplete="off"></el-input>
                  </el-form-item>
                </div>
                <div class="reserveC">
                  <el-form-item label="匯率：" :label-width="formLabelWidth">
                    <el-input v-model="booking_note.rate" autocomplete="off" id="inp"></el-input>
                  </el-form-item>
                  <el-form-item label="兌換金額:" :label-width="formLabelWidth">
                    <el-input v-model="booking_note.fromAmount" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="折算金額:" :label-width="formLabelWidth">
                    <el-input v-model="booking_note.toAmount" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="聯係方式:" :label-width="formLabelWidth" prop="ContactNumber">
                    <el-input v-model="booking_note.ContactNumber" autocomplete="off"></el-input>
                  </el-form-item>
                </div>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="reserve_note('booking_note')"
                  >{{$t('message.exchange.liji')}}</el-button>
                  <el-button @click="resetForm('booking_note')">{{$t('message.exchange.back')}}</el-button>
                </el-form-item>
              </el-form>
              <div class="success">
                <i class="icon iconfont icon-tijiaochenggong" style="fontSize: 3rem"></i>
                預約成功
                <div slot="footer" class="dialog-footer">
                  <el-button>返 回</el-button>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="電匯價" name="second">
        <span slot="label">
          <img
            src="../assets/images/banner_table_icon2_select.png"
            style="position: relative;
    top: -3px;"
            alt
          />
          <span style="padding: 0 20px">電匯價</span>
        </span>
        <div class="ex-bottom">
          <div class="have">
            <p>
              <span>你所擁有的</span>
            </p>
            <div class="haveC">
              <input
                type="text"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="booking_tt.fromAmount"
              />
              <select v-model="booking_tt.fromCurrency">
                <option v-for="tt in tt_buy" :key="tt.id">{{tt}}</option>
              </select>
            </div>
          </div>
          <div>
            <img style="margin-top: 70px;" src="../assets/images/banner_table_switch.png" alt />
          </div>
          <div class="get">
            <p>
              <span>你所獲得的</span>
            </p>
            <div class="haveC">
              <input
                type="text"
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="booking_tt.toAmount"
              />
              <select v-model="booking_tt.toCurrency">
                <option v-for="ttc in tt_code" :key="ttc.id">{{ttc}}</option>
              </select>
            </div>
          </div>
          <div
            style="width: 100%; text-align: center; font-family: Light;height: 70px;line-height: 70px;"
          >
            <span style="font-size:13px">
              <span>
                {{$t('message.exchange.huilv')}}：1
                <span class="base"></span>=
                <span class="rate1"></span>
                <span class="code"></span>&nbsp;&nbsp;&nbsp;&nbsp;(1
                <span class="code"></span>=
                <span class="rate2"></span>
                <span class="base"></span>)
              </span>
            </span>
          </div>
          <div class="reserver-btn">
            <el-button type="text" @click="dialogFormVisible = true">網上預約</el-button>
            <el-dialog title="網上預約" :visible.sync="dialogFormVisible">
              <el-form :model="booking_tt" :rules="rules" ref="booking_tt">
                <el-form-item label="交易類型:" :label-width="formLabelWidth">
                  <el-select v-model="booking_tt.typeNmae" placeholder="請選擇交易類型">
                    <el-option
                      :label="exT"
                      v-for="exT in exchangeType"
                      :key="exT.value"
                      :value="exT"
                    >{{exT}}</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="交易貨幣:" :label-width="formLabelWidth">
                  <el-select v-model="booking_tt.fromCurrency" placeholder="請選擇需要兌換的貨幣">
                    <el-option :label="tt" v-for="tt in tt_buy" :key="tt.value" :value="tt">{{tt}}</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="兌換貨幣:" :label-width="formLabelWidth">
                  <el-select v-model="booking_tt.toCurrency" placeholder="請選擇需要兌換的貨幣">
                    <el-option
                      :label="ttc"
                      v-for="ttc in tt_code"
                      :key="ttc.value"
                      :value="ttc"
                    >{{ttc}}</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="匯率：" :label-width="formLabelWidth">
                  <el-input v-model="booking_tt.rate" autocomplete="off" id="inp"></el-input>
                </el-form-item>
                <el-form-item label="兌換金額:" :label-width="formLabelWidth">
                  <el-input v-model="booking_tt.fromAmount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="折算金額:" :label-width="formLabelWidth">
                  <el-input v-model="booking_tt.toAmount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="姓名:" :label-width="formLabelWidth" prop="ContactPerson">
                  <el-input v-model="booking_tt.ContactPerson" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="聯係方式:" :label-width="formLabelWidth" prop="ContactNumber">
                  <el-input v-model="booking_tt.ContactNumber" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="郵箱：" :label-width="formLabelWidth" prop="ContactEmail">
                  <el-input v-model="booking_tt.ContactEmail" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item class="center">
                  <el-button type="primary" @click="reserve_tt('booking_tt')">立即创建</el-button>
                  <el-button @click="resetForm('booking_tt')">重置</el-button>
                </el-form-item>
              </el-form>
              <div class="success">
                <i class="icon iconfont icon-tijiaochenggong" style="fontSize: 3rem"></i>
                预约成功
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="reserve('booking_tt')">返 回</el-button>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import axios from 'axios'

// eslint-disable-next-line no-unused-vars
import $ from 'jquery'
import { setTimeout } from 'timers'
export default {
  inject: ['reload'],
  data() {
    return {
      activeName: 'first', // 选项卡默认展示
      currency2: [],
      exchangeType: [],
      note_buy: ['HKD'],
      tt_buy: ['CNY'],
      tt_code: [],
      tt1: ['CNY'],
      tt2: ['HKD', 'USD'],
      dialogFormVisible: false,
      formLabelWidth: '120px',
      rules: {
        ContactPerson: [
          { required: true, message: '請輸入姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '長度在 2 到 5 个字符', trigger: 'blur' }
        ],
        ContactEmail: [
          { required: true, message: '請輸入郵箱地址', trigger: 'blur' },
          { type: 'email', message: '格式不對', trigger: 'blur' }
        ],
        ContactNumber: [
          { required: true, message: '請輸入手機號碼', trigger: 'blur' },
          { pattern: /^[1][3-8]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$/, message: '請輸入正確的手機號碼格式' }
        ]
      },
      booking_tt: {
        'ref': '',
        'transaction_id': '', // 交易id
        'method': '',
        'type': 'tt', // 交易的类型
        'typeNmae': '電匯價',
        'rate': '0', // 汇率
        'fromCurrency': 'HKD', // 支出币种
        'fromAmount': '1000.00', // 支出的金额
        'toCurrency': 'CNY', // 交易币种
        'toAmount': '1000.00', // 交易的金额
        'contactPerson': '', // 联系人
        'contactNumber': '', // 联系方式
        'contactEmail': '', // 联系邮件
        'appointmentDate': '2019-07-05', // 时间
        'status': 'pending', // 状态
        'ContactPerson': '',
        'ContactEmail': '',
        'ContactNumber': ''
      },
      booking_note: {
        'ref': '',
        'transaction_id': '', // 交易id
        'method': '',
        'type': 'note', // 交易的类型
        'typeNmae': '現金價',
        'rate': '0', // 汇率
        'fromCurrency': 'HKD', // 支出币种
        'fromAmount': '1000.00', // 支出的金额
        'toCurrency': 'CNY', // 交易币种
        'toAmount': '1000.00', // 交易的金额
        'contactPerson': '', // 联系人
        'contactNumber': '', // 联系方式
        'contactEmail': '', // 联系邮件
        'appointmentDate': '2019-07-05', // 时间
        'status': 'pending', // 状态
        'ContactPerson': '',
        'ContactEmail': '',
        'ContactNumber': ''
      }
    }
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleClick(tab, event) {
    },
    reserve_tt(booking) {
      let api2 = 'https://api.trustpassex.com/v1/orders/book'
      axios.post(api2, {
        'booking': {
          'ref': this.booking_tt.ref,
          'transaction_id': this.booking_tt.transaction_id,
          'method': this.booking_tt.method,
          'type': this.booking_tt.type,
          'rate': this.booking_tt.rate,
          'fromCurrency': this.booking_tt.fromCurrency,
          'fromAmount': this.booking_tt.fromAmount,
          'toCurrency': this.booking_tt.toCurrency,
          'toAmount': this.booking_tt.toAmount,
          'contactPerson': this.booking_tt.contactPerson,
          'contactNumber': this.booking_tt.contactNumber,
          'contactEmail': this.booking_tt.contactEmail,
          'appointmentDate': this.booking_tt.appointmentDate,
          'status': this.booking_tt.status,
          'typeNmae': this.booking_tt.typeNmae
        },
        headers: { 'language': 'cht' }
      }).then(response => {
        this.$refs[booking].validate((valid) => {
          if (valid) {
            $('.el-form').css('display', 'none')
            $('.success').css('display', 'block')
            setTimeout(this.reload, 2500)
          } else {
            alert('請填寫正確的信息')
            return false
          }
        })
      }).catch(err => {
        console.log(err)
      })
    },
    reserve_note(booking) {
      let api2 = 'https://api.trustpassex.com/v1/orders/book'
      axios.post(api2, {
        'booking': {
          'ref': this.booking_note.ref,
          'transaction_id': this.booking_note.transaction_id,
          'method': this.booking_note.method,
          'type': this.booking_note.type,
          'rate': this.booking_note.rate,
          'fromCurrency': this.booking_note.fromCurrency,
          'fromAmount': this.booking_note.fromAmount,
          'toCurrency': this.booking_note.toCurrency,
          'toAmount': this.booking_note.toAmount,
          'contactPerson': this.booking_note.contactPerson,
          'contactNumber': this.booking_note.contactNumber,
          'contactEmail': this.booking_note.contactEmail,
          'appointmentDate': this.booking_note.appointmentDate,
          'status': this.booking_note.status
        }
      }).then(response => {
        this.$refs[booking].validate((valid) => {
          if (valid) {
            $('.el-form').css('display', 'none')
            $('.success').css('display', 'block')
            setTimeout(this.reload, 2500)
          } else {
            alert('請填寫正確的信息')
            return false
          }
        })
      }).catch(err => {
        console.log(err)
      })
    },
    test1() {
      let apinote = 'https://api.trustpassex.com/v1/rates/notes'
      let apitt = 'https://api.trustpassex.com/v1/rates/tt'
      axios.get(apinote).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          this.note_buy.push(response.data.data[i].code)
        }
      }).catch(err => {
        console.log(err)
      })
      axios.get(apitt).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          this.tt_buy.push(response.data.data[i].base)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    test2_tt() {
      let api3 = 'https://api.trustpassex.com/v1/rates/exchange'
      // eslint-disable-next-line no-undef
      axios
        .get(api3, {
          params: {
            fromCurrency: this.booking_tt.fromCurrency,
            toCurrency: this.booking_tt.toCurrency,
            amount: this.booking_tt.fromAmount,
            type: this.booking_tt.type,
            typeNmae: this.booking_tt.typeNmae
          }
        })
        .then(response => {
          this.booking_tt.toAmount = parseFloat(response.data.data[0].convertAmount).toFixed(2)
          this.booking_tt.rate = parseFloat(response.data.data[0].convertRate).toFixed(4)
        })
        .catch(err => {
          console.log(err)
        })
    },
    test2_note() {
      let api3 = 'https://api.trustpassex.com/v1/rates/exchange'
      // eslint-disable-next-line no-undef
      axios
        .get(api3, {
          params: {
            fromCurrency: this.booking_note.fromCurrency,
            toCurrency: this.booking_note.toCurrency,
            amount: this.booking_note.fromAmount,
            type: this.booking_note.type,
            typeNmae: this.booking_note.typeNmae
          },
          headers: { 'language': 'cht' }
        })
        .then(response => {
          this.booking_note.toAmount = parseFloat(response.data.data[0].convertAmount).toFixed(2)
          this.booking_note.rate = parseFloat(response.data.data[0].convertRate).toFixed(4)
        })
        .catch(err => {
          console.log(err)
        })
    },
    text3() {
      if ($('#pane-first').css('display') === 'block') {
        this.booking_note.type = 'note'
        $('.base').text($('#pane-first .have option:selected').val())
        $('.code').text($('#pane-first .get  option:selected').val())
        $('.rate1').text(this.booking_note.rate)
        $('.rate2').text(parseFloat(1 / this.booking_note.rate).toFixed(4))
        this.test2_note()
      } else {
        this.booking_note.type = 'tt'
      }

      if ($('#pane-second').css('display') === 'block') {
        this.booking_tt.type = 'tt'
        $('.base').text($('#pane-second .have option:selected').val())
        $('.code').text($('#pane-second .get  option:selected').val())
        $('.rate1').text(this.booking_tt.rate)
        $('.rate2').text(parseFloat(1 / this.booking_tt.rate).toFixed(4))
        this.test2_tt()
        if ($('#pane-second option:selected').val() === 'CNY') {
          this.tt_code = this.tt2
        } else if ($('#pane-second option:selected').val() === 'HKD') {
          this.tt_code = this.tt1
          this.booking_tt.toCurrency = 'CNY'
        } else if ($('#pane-second option:selected').val() === 'USD') {
          this.tt_code = this.tt1
          this.booking_tt.toCurrency = 'CNY'
        }
      } else {
        this.booking_tt.type = 'note'
      }
    }
  },
  components: {
  },
  mounted() {
    this.test1()
    this.text3()
  },
  updated() {
    this.text3()
  }
}
</script>
<style lang="scss">
.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 0;
  height: 93px;
  line-height: 93px;
  font-size: 22px;
}
.el-tabs--top .el-tabs__item.is-top:last-child {
  font-size: 20px;
}
.el-tabs__item:hover {
  color: #e60012;
  cursor: pointer;
}
.el-tabs__item.is-active {
  color: #e60012;
}
.el-tabs__active-bar {
  background: #e60012;
}
.el-tabs__nav {
  width: 100%;
  height: 93px;
}
.el-tabs__item {
  width: 50%;
  text-align: center;
}
.ex-bottom {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  width: 92%;
  margin: 0 auto;
  justify-content: space-around;
}
.have,
.get {
  flex: 0 45%;
  padding: 0 10px;
  box-sizing: border-box;
  width: 40%;
  margin: 20px 0 0 0;
  border-radius: 7px;
}
.haveC {
  height: 53px;
  display: flex;
  flex-wrap: nowrap;
}
.have p,
.get p {
  height: 50px;
  border-radius: 7px 7px 0 0;
  margin: 0;
  padding: 0 5%;
  line-height: 50px;
  width: 100%;
  background: #e6e6e6;
}
.have p span,
.get p span {
  position: relative;
  top: 0.4rem;
}
.have input,
.have select,
.get input,
.get select {
  font-size: 20px;
  outline: none;
  border: none;
}
.have input,
.get input {
  border: 1px solid #e6e6e6;
  width: 65%;
  padding: 0 5%;
  border-right: 1px solid #e6e6e6;
}
.have select,
.get select {
  border: 1px solid #e6e6e6;
  width: 35%;
}
.el-time-spinner.has-seconds li {
  display: block;
}
.reserver-btn {
  font-family: Medium;
  text-align: center;
  font-size: 20px;
  border-radius: 7px;
  height: 10%;
  width: 50%;
  margin: auto;
  border: none;
  background: #e60012;
}
.reserver-btn > .el-button--text {
  color: white;
  outline: none;
  width: 100%;
  height: 100%;
}
.el-tabs {
  height: 100%;
}
.el-tabs__content {
  height: 84%;
}
.el-tab-pane {
  height: 100%;
}

.el-tabs__active-bar {
  width: 50%;
}
.v-modal {
  opacity: 0;
}
.exchange .el-form-item__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.exchange .el-form {
  display: flex;
  flex-wrap: wrap;
}
.exchange .el-form .reserveC {
  flex: 0 50%;
  padding: 0 20px;
}
.exchange .el-form {
  font-family: Light;
  font-size: 14px;
}
.exchange .el-form-item__label {
  font-size: 18px;
}
.exchange .el-form-item.center {
  width: 100%;
  text-align: center;
}
.exchange .el-dialog__title {
  color: #e60012;
  font-family: Regular;
  font-size: 24px;
  margin: 40px 0;
  display: inline-block;
}
.exchange .el-form-item {
  margin-bottom: 32px;
}
.exchange .el-form-item__content button {
  margin: 0 30px;
}
.exchange .el-form-item__content button:nth-child(1) {
  background: #e60012;
  border: none;
  font-family: Medium;
}
.exchange .el-form-item__content button:nth-child(2) {
  background: #bdbdbd;
  border: none;
  color: white;
  font-family: Medium;
}
.exchange .el-form-item {
  width: 100%;
}
.ex-bottom > div:nth-child(2) img {
  margin-top: 70px;
}
.ex-bottom > div:nth-child(4) {
  width: 100%;
  text-align: center;
  font-family: Light;
  height: 70px;
  line-height: 70px;
}
@media (max-width: 866px) {
  .have p,
  .get p {
    height: 26px;
    line-height: 26px;
    font-size: 12px;
  }
  .haveC {
    height: 35px;
    font-size: 12px;
  }
  .have input,
  .get input {
    font-size: 12px;
    width: 65%;
  }
  .ex-bottom > div:nth-child(2) img {
    margin-top: 45px;
  }
  .ex-bottom > div:nth-child(4) {
    width: 100%;
    text-align: center;
    font-family: Light;
    height: 36px;
    line-height: 36px;
  }
  .have select,
  .get select {
    font-size: 12px;
    width: 35%;
  }
  .have,
  .get {
    padding: 0 0px;
    flex: 0 40%;
  }
}
// @media (max-width: 1450px) {
//   .exchange {
//     border: 1px solid #e6e6e6;
//     margin: 5% auto;
//   }
// }
// .el-form-item__label{ text-align: center !important};
// .el-dialog{ width:60%;}
// .el-dialog__header{ padding: 20px 20px 0px !important};
// .el-dialog__header{ text-align: center};
// .el-select{ width: 100%;}
// .reserver-btn button { outline: none}
// .success{ text-align: center;font-size: 2.5rem;font-weight: bold; color: #67C23A;display: none;}
// @media (max-width: 866px) {
//   .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
//     padding-left: 0;
//     height: 2rem;
//     line-height: 2rem;
//   }
//   .el-form-item.center{
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//   }
//   .el-dialog__wrapper .el-dialog { width: 100% !important;margin: 0 !important}
//   .el-dialog{ width:50%;}
//   .exchange {
//     position: static;
//     width: 100%;
//   }
//   .have,
//   .get {
//     margin: 3% 0;
//     background: #f6f6f6;
//   }
//   .have p,
//   .get p {
//     border-bottom: 1px solid white;
//     margin: 0;
//     line-height: 30px;
//   }
//   .have p span,
//   .get p span {
//     position: static;
//   }
//   .have input,
//   .get input,
//   .have select,
//   .get select {
//     line-height: 45px;
//     background: transparent;
//     font-size: 15px;
//     height: 35px !important;
//   }
// }
</style>
