<template>
  <div class="error">
    <div>
      <div>
        <img src="../assets/images/error.jpg" alt="error" width="100%">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'error',
  data () {
    return {
    }
  }
}
</script>
<style scoped>
 .error>div{width: 30%;margin: 10% auto;}
 @media (max-width: 1182px) {
   .error>div{width: 80%;margin: 30% auto;}
 }
</style>
