<template>
    <div class="ChengTong">
        <v-nav></v-nav>
        <div class="CTcontent">
          <div class="top"></div>
          <div class="Ctchange">
            <p>誠通找換</p>
            <div></div>
            <p>誠信為本，通達四方</p>
          </div>
          <div class="center">
            <div class="c1">
              <div>
                <img src="../assets/images/ct2.jpg" alt="" width="100%" height="100%">
              </div>
              <div>
                <p>示例标题示例标题示例标题</p>
                <span>说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字</span>
              </div>
            </div>
            <div class="c1">
              <div>
                <img src="../assets/images/ct3.jpg" alt="" width="100%" height="100%">
              </div>
              <div>
                <p>示例标题示例标题示例标题</p>
                <span>说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字</span>
              </div>
            </div>
            <div class="c1">
              <div>
                <img src="../assets/images/ct4.jpg" alt="" width="100%" height="100%">
              </div>
              <div>
                <p>示例标题示例标题示例标题</p>
                <span>说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字</span>
              </div>
            </div>
            <div class="c1">
              <div>
                <img src="../assets/images/ct5.jpg" alt="" width="100%" height="100%">
              </div>
              <div>
                <p>示例标题示例标题示例标题</p>
                <span>说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字</span>
              </div>
            </div>
          </div>
          <div class="trust">
            <div class="trust-left">
              <img src="../assets/images/hg.jpg" alt="">
            </div>
            <div class="trust-right">

            </div>
          </div>
          <div class="Guarantee">
            <ul>
              <li>
                <i class="el-icon-trophy"></i>
                <span>優質好評</span>
              </li>
              <li>
                <i class="el-icon-circle-check"></i>
                <span>品質保證</span>
              </li>
              <li>
                <i class="el-icon-sunny"></i>
                <span>服務保障</span>
              </li>
              <li>
                <i class="el-icon-coffee-cup"></i>
                <span>客戶滿意</span>
              </li>
              <li>
                <i class="el-icon-s-check"></i>
                <span>誠信可靠</span>
              </li>
              <li>
                <i class="el-icon-star-off"></i>
                <span>五星好評</span>
              </li>
              <li>
                <i class="el-icon-magic-stick"></i>
                <span>您的不二之選</span>
              </li>
            </ul>
          </div>
        </div>
        <v-footer></v-footer>
    </div>
</template>
<script>
import Reserve from './reserve' // 网上预约组件
import Nav from './nav' // 导航组建
import Footer from './footer' // 尾部组建
export default {
  data () {
    return {

    }
  },
  components: {
    'v-reserve': Reserve,
    'v-nav': Nav,
    'v-footer': Footer
  }
}
</script>
<style>
.ChengTong{
  background: #FFFFFF;
}
.CTcontent{
  margin: auto;
  width: 90%;
  /* background: floralwhite; */
}
.CTcontent .top{
  margin: 3% 0;
  height: 133px;
  background: url('../assets/images/profile.png')
}
.ChengTong .center{
  margin: 2% 0;
  width: 100%;
  height: 30rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.c1{
  background: #F9F9F9;
  margin: 0 1%;
  float: left;
  width: 20%;
}
.c1>div:nth-child(1){
  overflow: hidden;
  height: 60%;
}
.c1>div:nth-child(1)>img{
  transition: all 1s;
}
.c1>div:nth-child(2){
  width: 90%;
  height: 40%;
  margin: auto;
  padding: 5%;
}
.c1>div:nth-child(1):hover img{
  transform: scale(1.1);
  transition: all 1s;
}
.c1:hover {
  box-shadow: 10px 5px 5px #e6e6e6;
  background: white;
}
.c1 p{
  text-align: center;
}
.c1 span{
  font-size: 15px;
}
.Ctchange{
  width: 100%;
}
.Ctchange p:nth-child(1){
  text-align: center;
  font-size: 2.5rem;
  color: #D8BE8B;
}
.Ctchange p:nth-child(3){
  margin: 1% 0 0 0;
  text-align: center;
  font-size: 1rem;
  color: #666;
}
.Ctchange>div{
  border-top: 1px solid #D8BE8B;
  width: 4%;
  margin: auto;
  transition: all 1s;
}
.Ctchange:hover div{
  width: 8%;
  transition: all 1s;
}
.trust{
  margin: 3% 0;
}
.ChengTong .trust img{
  height: 30rem;
  display: block;
  margin: auto;
}
.Guarantee{
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}
.Guarantee>ul>li{
  float: left;
  height: 140px;
  padding-top: 25px;
  width: 14.2%;
  overflow: hidden;
  text-align: center;
}
.Guarantee>ul>li i,.Guarantee>ul>li span{
  transition: all 0.7s;
  display: block
}
.Guarantee>ul>li i{ font-size: 2.5rem;}
.Guarantee>ul>li span{ margin: 8% 0 0 0;}
.Guarantee>ul>li:hover i,.Guarantee>ul>li:hover span{
  color: #D8BE8B;
  transition: all 0.7s;
}
</style>
