<template>
    <div class="reserve">
      <el-button type="text" @click="dialogFormVisible = true">網上預約</el-button>
      <el-dialog title="網上預約" :visible.sync="dialogFormVisible" >
          <el-form :model="booking" :rules="rules" ref="booking">
            <div class="reserveC">
              <el-form-item label="交易類型:" :label-width="formLabelWidth">
                  <el-select id="type" v-model="booking.typeNmae" placeholder="現金價">
                    <el-option :label="exT" v-for="exT in exchangeType" :key="exT.value" :value="exT">{{exT}}</el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="交易貨幣:" :label-width="formLabelWidth">
                  <el-select id="base" v-model="booking.fromCurrency" placeholder="請選擇需要兌換的貨幣" >
                    <el-option :label="cur" v-for="cur in currency" :key="cur.value" :value="cur">{{cur}}</el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="兌換貨幣:" :label-width="formLabelWidth">
                  <el-select v-model="booking.toCurrency" placeholder="請選擇需要兌換的貨幣" >
                    <el-option :label="cur" v-for="cur in currency" :key="cur.value" :value="cur">{{cur}}</el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="姓名:" :label-width="formLabelWidth" prop="contactPerson">
                  <el-input v-model="booking.contactPerson" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="郵箱：" :label-width="formLabelWidth" prop="contactEmail" >
                  <el-input v-model="booking.contactEmail" autocomplete="off"></el-input>
              </el-form-item>
            </div>
            <div class="reserveC">
              <el-form-item label="匯率：" :label-width="formLabelWidth" >
                <el-input v-model="booking.rate" autocomplete="off" id="inp"></el-input>
              </el-form-item>
              <el-form-item label="兌換金額:" :label-width="formLabelWidth" >
                  <el-input v-model="booking.fromAmount" autocomplete="off" ></el-input>
              </el-form-item>
              <el-form-item label="折算金額:" :label-width="formLabelWidth" >
                  <el-input v-model="booking.toAmount" autocomplete="off" ></el-input>
              </el-form-item>
              <el-form-item label="聯係方式:" :label-width="formLabelWidth" prop="contactNumber">
                  <el-input v-model="booking.contactNumber" autocomplete="off"></el-input>
              </el-form-item>
            </div>
            <el-form-item class="center">
              <el-button type="primary" @click="reserve('booking')">立即创建</el-button>
              <el-button @click="resetForm('booking')">重置</el-button>
            </el-form-item>
            </el-form>
            <div class="success">
              <i class="icon iconfont icon-tijiaochenggong" style="fontSize: 3rem"></i>
              预约成功
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="reserve('booking')">返 回</el-button>
              </div>
            </div>
      </el-dialog>
    </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import axios from 'axios'

// eslint-disable-next-line no-unused-vars
import $ from 'jquery'
import { setTimeout } from 'timers'
export default {
  inject: ['reload'],
  data () {
    return {
      currency: [],
      exchangeType: ['電匯價', '現金價'],
      note_buy: ['HKD'],
      tt_buy: ['CNY'],
      tt_code: [],
      tt1: ['CNY'],
      tt2: ['HKD', 'USD'],
      dialogFormVisible: false,
      formLabelWidth: '90px',
      rules: {
        contactPerson: [
          { required: true, message: '請輸入姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '長度在 2 到 5 个字符', trigger: 'blur' }
        ],
        contactEmail: [
          { required: true, message: '請輸入郵箱地址', trigger: 'blur' },
          { type: 'email', message: '格式不對', trigger: 'blur' }
        ],
        contactNumber: [
          { required: true, message: '請輸入手機號碼', trigger: 'blur' },
          { pattern: /^1[34578]\d{9}$/, message: '目前只支持中國大陸手機號碼' }
        ]
      },
      booking: {
        'ref': '',
        'transaction_id': '', // 交易id
        'method': '',
        'type': 'tt', // 交易的类型
        'typeNmae': '',
        'rate': '0', // 汇率
        'fromCurrency': 'HKD', // 支出币种
        'fromAmount': '1000.00', // 支出的金额
        'toCurrency': 'CNY', // 交易币种
        'toAmount': '1000.00', // 交易的金额
        'contactPerson': '', // 联系人
        'contactNumber': '', // 联系方式
        'contactEmail': '', // 联系邮件
        'appointmentDate': '2019-07-05', // 时间
        'status': 'pending' // 状态
      }
    }
  },
  methods: {
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    handleClick (tab, event) {
    },
    reserve (booking) {
      let api2 = 'https://api.trustpassex.com/v1/orders/book'
      axios.post(api2, {
        'booking': {
          'ref': this.booking.ref,
          'transaction_id': this.booking.transaction_id,
          'method': this.booking.method,
          'type': this.booking.type,
          'typeNmae': this.booking.typeNmae,
          'rate': this.booking.rate,
          'fromCurrency': this.booking.fromCurrency,
          'fromAmount': this.booking.fromAmount,
          'toCurrency': this.booking.toCurrency,
          'toAmount': this.booking.toAmount,
          'contactPerson': this.booking.contactPerson,
          'contactNumber': this.booking.contactNumber,
          'contactEmail': this.booking.contactEmail,
          'appointmentDate': this.booking.appointmentDate,
          'status': this.booking.status
        }
      }).then(response => {
        this.$refs[booking].validate((valid) => {
          if (valid) {
            $('.el-form').css('display', 'none')
            $('.success').css('display', 'block')
            setTimeout(this.reload, 2500)
          } else {
            alert('請填寫正確的信息')
            return false
          }
        })
      }).catch(err => {
        console.log(err)
      })
    },
    test1 () {
      let apinote = 'https://api.trustpassex.com/v1/rates/notes'
      let apitt = 'https://api.trustpassex.com/v1/rates/tt'
      axios.get(apinote).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          this.note_buy.push(response.data.data[i].code)
        }
      }).catch(err => {
        console.log(err)
      })
      axios.get(apitt).then(response => {
        for (let i = 0; i < response.data.data.length; i++) {
          this.tt_buy.push(response.data.data[i].base)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    test2 () {
      let api3 = 'https://api.trustpassex.com/v1/rates/exchange'
      // eslint-disable-next-line no-undef
      axios
        .get(api3, {
          params: {
            fromCurrency: this.booking.fromCurrency,
            toCurrency: this.booking.toCurrency,
            amount: this.booking.fromAmount,
            type: this.booking.type
          }
        })
        .then(response => {
          this.booking.toAmount = parseFloat(response.data.data[0].convertAmount).toFixed(2)
          this.booking.rate = parseFloat(response.data.data[0].convertRate).toFixed(2)
        })
        .catch(err => {
          console.log(err)
        })
    },
    text4 () {
      if ($('#type').val() === '電匯價') {
        this.currency = this.tt_buy
        this.booking.type = 'tt'
        console.log($('#base option:selected').val())
      } else if ($('#type').val() === '現金價') {
        this.currency = this.note_buy
        this.booking.type = 'note'
      }
    }
  },
  components: {
  },
  mounted () {
    this.test1()
    this.test2()
    this.text4()
    // this.text3()
  },
  updated () {
    this.test2()
    // this.text3()
    this.text4()
  }
}
</script>
<style lang="scss">
  .reserve .el-form-item__label{ text-align: center !important};
  .reserve .el-dialog{ width:55%; height: 700px; margin: 3.7% auto !important;}
  .reserve .el-dialog__header{ padding: 20px 20px 0px !important};
  .reserve .el-dialog__header{ text-align: center};
  .reserve .el-select{ width: 100%;}
  .reserver-btn button { outline: none}
  .success{ text-align: center;font-size: 2.5rem;font-weight: bold; color: #67C23A;display: none;}
  .reserve .v-modal{
    z-index: 0 !important;
  }
  .reserve .el-form-item__content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .reserve .el-form{
    display: flex;
    flex-wrap: wrap;
  }
  .reserve .el-form .reserveC{
    flex: 0 50%;
    padding: 0 20px;
  }
  .reserve .el-form{font-family: Light;font-size: 14px;}
  .reserve .el-form-item__label{ font-size: 18px;}
  .reserve .el-form-item.center{ width: 100%; text-align: center;}
  .reserve .el-dialog__title{
    color: #E60012;
    font-family: Regular;
    font-size: 24px;
    margin: 40px 0;
    display: inline-block;
  }
  .reserve .el-form-item {
    margin-bottom: 32px;
  }
  .reserve .el-form-item__content button{ margin: 0 30px;}
  .reserve .el-form-item__content button:nth-child(1){
    background: #E60012;
    border: none;
    font-family: Medium;
  }
  .reserve .el-form-item__content button:nth-child(2){
    background: #BDBDBD;
    border: none;
    color: white;
    font-family: Medium;
  }
  @media (max-width: 866px) {
    .reserve .el-form-item.center{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .reserve .el-dialog__wrapper .el-dialog { width: 90% !important;}
    .reserve .el-form-item__content { width: 65%;}
    .reserve .el-dialog{ width:50%; margin: 5% 5%!important;}
  }
</style>
