<template>
  <div class="cmarkets7">
      <v-nav></v-nav>
      <img src="../assets/images/banner1.jpg" alt="">
      <div class="center">
          <div class="content">
            <h2>数据快评：美国9月CPI报告显示月率0增长创下7个月新低，了解报告细节！</h2>
            <p>摘要：尽管美国9月CPI报告显示CPI年率与前值持平，但月率显示0增长并创下7个月新低。
            <p>10 月10日周四公布的数据显示，美国9月消费者物价指数（CPI）月率持平，并且创下了7个月新低！而年率录得1.7%，低于预期的1.9%；剔除食品及能源的核心CPI月率上升0.1%，年率录得2.4%、符合预期的2.4%。如何利用重大消息来交易？</p>
            <p>分项数据显示，美国9月食品、所有项目扣除食品及能源、扣除能源服务的服务物价上升，汽油、燃料油、公用（管道）燃气的物价下行。住宿和食品指数的增加被能源和二手轿车及卡车指数的下降所抵消，导致经季节调整的所有项目指数持平。具体地如下表所示。</p>
            <p>现在查看最新的IG客户情绪来帮助您了解IG散户的美元指数、欧元/美元、英镑/美元以及更大股指的持仓变化，进一步获得主要货币对和原油、黄金还有各大股指的走势指引</p>
            <p>数据还显示，提出通胀影响后，美国7实际平均时薪增长1.2%，实际平均周薪增长0.9%，二者分别不及其前值1.4%和1.1%。</p>
            <p>在DailyFX，我们研究IG实时交易超过3000万次，发现交易者犯了我们的《成功交易者的特征》报告中第一个错误。了解哪里出了问题，并做出必要的调整是像专业人士那样交易的第一步。现在下载《成功者交易手册》</p>
        </div>
      </div>
      <!-- prompt -->
      <div class="p">
          <p>免责声明：文章来至网络，文章表达观点不代表本站观点，文章版权属于原作者所有，若有侵权，请联系本站站长处理！</p>
      </div>
      <v-footer></v-footer>
  </div>
</template>

<script>
import Nav from './nav'
import Footer from './footer'
export default {
  name: 'cmarkets7',
  data () {
    return {

    }
  },
  components: {
    'v-nav': Nav,
    'v-footer': Footer
  }
}
</script>

<style scpped>
    .cmarkets7{background: #eee;}
    .cmarkets7>div:nth-child(1){background: white;}
    .center{
        width: 85%;
        margin: 2% auto;
    }
    .center .content{
        width: 70%;
        margin: auto;
    }
    .cmarkets7 .content p{
        margin: 2% 0;
    }
    h2{text-align: center;}
    .p{text-align: center;padding: 3% 0;background: white;}
</style>
<style>

</style>
