<template>
  <div class="All">
    <h3 class="time">更新時間：{{ Maxdata }}</h3>
    <h2 style="text-align: center">觀塘的人民幣港幣匯率</h2>
    <el-table
      :data="allData1"
      :header-cell-style="{ background: 'red', color: 'white' }"
      style="height:100%;"
    >
      <el-table-column prop="" label="國家" width="180"> </el-table-column>
      <el-table-column prop="code" label="貨幣" width="180"></el-table-column>
      <el-table-column prop="name" label="貨幣" width="180"></el-table-column>
      <el-table-column prop="buy_rate" label="誠通買入"></el-table-column>
      <el-table-column prop="sell_rate" label="誠通賣出"></el-table-column>
    </el-table>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import axios from "axios";

export default {
  name: "exchange",
  data() {
    return {
      allData: [],
      allData1: [{ code: "", name: "", buy_rate: "", sell_rate: "" }],
      dataMax: [],
      Maxdata: ""
    };
  },
  methods: {
    getexchange() {
      let api = "https://api.trustpassex.com/v1/rates/notes";
      // eslint-disable-next-line no-undef
      axios
        .get(api, {
          headers: { language: "cht" }
        })
        .then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.allData.push(response.data.data[i]);
            this.allData1.push({
              code: response.data.data[i].code,
              name: response.data.data[i].name,
              buy_rate: response.data.data[i].buy_rate,
              sell_rate: response.data.data[i].sell_rate
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getTime() {
      let api = "https://api.trustpassex.com/v1/rates/notes";
      axios
        .get(api, {
          headers: { language: "cht" }
        })
        .then(response => {
          for (let i = 0; i < response.data.data.length; i++) {
            this.dataMax.push(
              new Date(response.data.data[i].updated_at).getTime() - 28800000
            );
          }
          this.Maxdata = this.$moment(
            Math.max.apply(null, this.dataMax)
          ).format("YYYY-MM-DD HH:MM");
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.getexchange();
    this.getTime();
  }
};
</script>
<style lang="scss">
.All .el-table td,
.el-table th {
  padding: 8px 0px !important;
}
.All
  .el-table.el-table--fit.el-table--enable-row-hover.el-table--enable-row-transition {
  height: 1900px !important;
}
.All .time {
  margin: 1% 0 3% 0;
  font-size: 35px;
  text-align: center;
  color: #245482;
}
.All .el-table__row > td:nth-child(1),
.el-table__row > td:nth-child(2),
.el-table_1_column_1.is-leaf,
.el-table_1_column_2.is-leaf {
  width: 15% !important;
}
.All .el-table__row > td:nth-child(3),
.el-table_1_column_3.is-leaf {
  width: 30% !important;
}
.All .el-table__row > td:nth-child(1) {
  background-size: 55% !important;
}
.All .el-table__body,
.el-table__header {
  width: auto !important;
  font-size: 2.7rem;
}
.All .el-table,
.el-table--fit,
.el-table--scrollable-y,
.el-table--enable-row-hover,
.el-table--enable-row-transition {
  margin: 1rem auto;
  width: 95% !important;
  text-align: center;
}
.All .el-table__row:nth-child(1) {
  display: none;
}
.All .cell {
  text-align: center;
}
.All .el-table__body tr {
  color: black;
  font-weight: bold;
}
.All .el-table__body tr:nth-child(even) {
  background-color: #ffe5cc;
  transition: all 0.3s;
}
.All .el-table__body tr:nth-child(odd) {
  background-color: #eeb5ac;
  transition: all 0.3s;
}
.All .el-table__body tr:nth-child(2) td:nth-child(1) {
  background: url("../assets/images/AED.png") center no-repeat;
}
.All .el-table__body tr:nth-child(3) td:nth-child(1) {
  background: url("../assets/images/AUD.png") center no-repeat;
}
.All .el-table__body tr:nth-child(4) td:nth-child(1) {
  background: url("../assets/images/CAD.png") center no-repeat;
}
.All .el-table__body tr:nth-child(5) td:nth-child(1) {
  background: url("../assets/images/CHF.png") center no-repeat;
}
.All .el-table__body tr:nth-child(6) td:nth-child(1) {
  background: url("../assets/images/CNH.png") center no-repeat;
}
.All .el-table__body tr:nth-child(7) td:nth-child(1) {
  background: url("../assets/images/DKK.png") center no-repeat;
}
.All .el-table__body tr:nth-child(8) td:nth-child(1) {
  background: url("../assets/images/EUR.png") center no-repeat;
}
.All .el-table__body tr:nth-child(9) td:nth-child(1) {
  background: url("../assets/images/GBP.png") center no-repeat;
}
.All .el-table__body tr:nth-child(10) td:nth-child(1) {
  background: url("../assets/images/IDR.png") center no-repeat;
}
.All .el-table__body tr:nth-child(11) td:nth-child(1) {
  background: url("../assets/images/JPY.png") center no-repeat;
}
.All .el-table__body tr:nth-child(12) td:nth-child(1) {
  background: url("../assets/images/KRW.png") center no-repeat;
}
.All .el-table__body tr:nth-child(13) td:nth-child(1) {
  background: url("../assets/images/KWD.png") center no-repeat;
}
.All .el-table__body tr:nth-child(14) td:nth-child(1) {
  background: url("../assets/images/MOP.png") center no-repeat;
}
.All .el-table__body tr:nth-child(15) td:nth-child(1) {
  background: url("../assets/images/MYR.png") center no-repeat;
}
.All .el-table__body tr:nth-child(16) td:nth-child(1) {
  background: url("../assets/images/NZD.png") center no-repeat;
}
.All .el-table__body tr:nth-child(17) td:nth-child(1) {
  background: url("../assets/images/PHP.png") center no-repeat;
}
.All .el-table__body tr:nth-child(18) td:nth-child(1) {
  background: url("../assets/images/SEK.png") center no-repeat;
}
.All .el-table__body tr:nth-child(19) td:nth-child(1) {
  background: url("../assets/images/SGD.png") center no-repeat;
}
.All .el-table__body tr:nth-child(20) td:nth-child(1) {
  background: url("../assets/images/THB.png") center no-repeat;
}
.All .el-table__body tr:nth-child(21) td:nth-child(1) {
  background: url("../assets/images/TWD.png") center no-repeat;
}
.All .el-table__body tr:nth-child(22) td:nth-child(1) {
  background: url("../assets/images/USD.png") center no-repeat;
}
.All .el-table__body tr:nth-child(23) td:nth-child(1) {
  background: url("../assets/images/VND.png") center no-repeat;
}
.All .el-table__body tr:nth-child(24) td:nth-child(1) {
  background: url("../assets/images/ZAR.png") center no-repeat;
}
.All .el-table__body tr:hover {
  background: #f5f7fa;
  transition: all 0.3s;
}
.All .el-table .cell {
  line-height: normal !important;
}
</style>
