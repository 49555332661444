<template>
  <div class="information">
      <!-- header -->
      <v-nav></v-nav>
      <!-- banner -->
      <div class="top">
          <img src="../assets/images/banner-fan.png" alt="" width="100%">
      </div>
      <!-- content -->
      <div class="centercontent">
          <div class="content">
            <div class="c">
                  <div><img src="../assets/images/news2_img.png" alt="new"></div>
                  <div>
                      <div>07-28</div>
                      <div>2023</div>
                  </div>
                  <div>
                      <h5><router-link to="/cmarkets2/">中國雙邊結算中人民幣佔比首超美元</router-link></h5>
                      <p>提要：根據中國國家外匯管理局的統計數據，《日本經濟新聞》7月25日按照結算貨幣進行分類，對企業、個人和投資者的跨境交易進行統計。統計數據顯示，2023年第二季度，人民幣在中國跨境交易中佔比達49%，首次超過美元。這主要是因為中國資本市場更加開放，中俄採用兩國人民幣結算的貿易增多...</p>
                      <span><router-link to="/cmarkets2/">查看详情 +</router-link></span>
                  </div>
              </div>
              <div class="c">
                  <div><img src="../assets/images/news3_img.png" alt="new"></div>
                  <div>
                      <div>07-04</div>
                      <div>2023</div>
                  </div>
                  <div>
                      <h5><router-link to="/cmarkets3/">中國一個月內連續降低美元存款利率 以阻人民幣繼續貶值...</router-link></h5>
                      <p>摘要：中國主要國有銀行在一個月內連續第二次降低美元存款利率，以阻止人民幣貶值進一步下滑...</p>
                      <span><router-link to="/cmarkets3/">查看详情 +</router-link></span>
                  </div>
              </div>
              <div class="c">
                  <div><img src="../assets/images/news5_img.png" alt="new"></div>
                  <div>
                      <div>06-27</div>
                      <div>2023</div>
                  </div>
                  <div>
                      <h5><router-link to="/cmarkets5/">人民幣急跌確認中國挑戰美元敗陣.分析：國際市場寧沽人仔要美金</router-link></h5>
                      <p>摘要：人民幣在岸及離岸價均創近7個月新低，當中離岸人民幣更一度大跌400基點。雖然中共一直高調將人民幣國際化，聯合部分發展中國家挑戰美元「霸權」地位，但最終敵不過市場力量。...</p>
                      <span><router-link to="/cmarkets5/">查看详情 +</router-link></span>
                  </div>
              </div>
              <div class="c">
                  <div><img src="../assets/images/news4_img.png" alt="new"></div>
                  <div>
                      <div>05-19</div>
                      <div>2023</div>
                  </div>
                  <div>
                      <h5><router-link to="/cmarkets4/">美元强势尚在，中国经济走软，人民币汇率再次破七</router-link></h5>
                      <p>摘要：华盛顿 — 人民币对美元的汇率星期四（5月18日）破七，达到一美元兑7.02元人民币。这也是人民币对美元汇率五个多月来的新低。1:7是人民币对美元汇率的心理大关，人民币对美元汇率星期三盘中首次破七，星期四尾盘一度甚至跌到7.05...</p>
                      <span><router-link to="/cmarkets4/">查看详情 +</router-link></span>
                  </div>
              </div>
              <div class="c">
                  <div><img src="../assets/images/news1_img.png" alt="new"></div>
                  <div>
                      <div>04-28</div>
                      <div>2023</div>
                  </div>
                  <div>
                      <h5><router-link to="/cmarkets1/">中國跨境交易中人民幣使用率首次超過美元 離挑戰「美元霸權」有多遠？</router-link></h5>
                      <p>摘要：中國外匯管理局近期公布， 在今年3月的中國跨境收支中，用人民幣交易的佔比達到48%，用美元的佔比47%，這是人民幣首次在該國跨境交易中的使用率超過美元。...</p>
                      <span><router-link to="/cmarkets1/">查看详情 +</router-link></span>
                  </div>
              </div>
          </div>
      </div>
      <!-- footer -->
      <v-footer></v-footer>
      <!-- 底部導航 -->
      <div class="call">
      <div>
        <div class="callphone">
          <!-- <img src="../assets/images/new_float_icon1.png" alt />
          <img class="callphones" src="../assets/images/float_phone.png" alt /> -->
          <el-tooltip class="item" effect="dark" placement="left">
            <div slot="content" class="slot">
              （上環總店）聯係方式 : (852) 3462 6432
            </div>
            <div>
              <img src="../assets/images/new_float_icon1.png" alt />
            </div>
          </el-tooltip>
        </div>
        <div class="callmail">
          <!-- <a href="mailto:hkchengtong@163.com">
            <img src="../assets/images/float_icon22.png" alt />
          </a>
          <img class="callmails" src="../assets/images/float_icon2.png" alt /> -->
          <el-tooltip class="item" effect="dark" placement="left">
            <div slot="content" class="slot1">
              e-mail : enquiry@trustpassex.com
            </div>
            <div>
              <a href="mailto:hkchengtong@163.com">
                <img src="../assets/images/float_icon22.png" alt />
              </a>
            </div>
          </el-tooltip>
        </div>
        <div class="callzixun">
          <!-- <a
            rel="nofollow"
            target="_blank"
            href="http://p.qiao.baidu.com/cps/chat?siteId=14068382&userId=29252090&cp=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D&cr=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D&cw=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D"
          >
            <img src="../assets/images/new_float_icon4.png" alt />
          </a>
          <img
            class="callzixuns"
            src="../assets/images/float_advisory.png"
            alt
          /> -->
          <el-tooltip class="item" effect="dark" placement="left">
            <div slot="content" class="slot3">
              在綫諮詢
            </div>
            <div>
              <a
                rel="nofollow"
                target="_blank"
                href="http://p.qiao.baidu.com/cps/chat?siteId=14068382&userId=29252090&cp=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D&cr=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D&cw=%E7%AC%AC%E4%B8%80%E6%89%B9%E4%BA%8C%E7%BA%A7%E5%9F%9F%E5%90%8D"
              >
                <img src="../assets/images/new_float_icon4.png" alt />
              </a>
            </div>
          </el-tooltip>
        </div>
        <div class="callqr">
          <img class="qr" src="../assets/images/new_float_icon3.png" alt />
          <img class="qrcode" src="../assets/images/new_float_code.png" alt />
        </div>
        <div class="JJ">
          <img class="J" src="../assets/images/new_float_icon5.png" alt />
          <!-- <img src="../assets/images/float_back.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from './nav'
import Footer from './footer'
export default {
  name: 'information',
  data () {
    return {

    }
  },
  components: {
    'v-nav': Nav,
    'v-footer': Footer
  }
}
</script>
<style scoped>
@import '../common/font/font.css';
.information>div:nth-child(1){background: white;}
.information .top{margin: 70px 0 0 0}
.centercontent{
  width: 75%;
  margin: 100px auto;
}
.c{
  display: flex;
  flex-wrap: wrap;
  margin: 3% 0;
}
.c p{
  padding: 3% 0 0 0;
  font-size: 14px;
  font-family: Light;
}
.c span{
  display: inline-block;
  float: right;
  font-size: 13px;
  color: red;
}
.c>div:nth-child(1){ flex: 0 0 40%;}
.c>div:nth-child(2){ flex: 0 0 8%;}
.c>div:nth-child(3){ flex: 0 0 52%;}
.c>div:nth-child(2)>div:nth-child(1){
  font-family: Regular;
  font-size: 20px;
}
.c>div:nth-child(2)>div:nth-child(2){
  font-family: Normal;
  font-size: 13px;
}
.c h5 a{
  color: black;
  text-decoration: none;
  font-family: Regular;
}
.c span a{
    font-family: Regular;
  color: red;
  text-decoration: none;
}
.c>div:nth-child(1){ text-align: center;}
// 底部导航
.call {
  z-index: 999;
  position: fixed;
  right: 10px;
  top: 33%;
}
.call img {
  width: 60px;
  height: 60px;
  display: block;
  margin: 5px 0;
}
.call > div {
  position: relative;
}
.callphones {
  display: none;
}
.callqr,
.callphone,
.callmail,
.callzixun {
  position: relative;
}
.callphone > img:nth-child(2),
.callmail > img:nth-child(2),
.callzixun > img:nth-child(2) {
  position: absolute;
  display: none;
}
.callqr > img:nth-child(2) {
  display: none;
  position: absolute;
  top: -35px;
  left: -130px;
  width: 130px;
  height: 120px;
}
.call .callphones {
  display: none;
  position: absolute;
  top: -4px;
  left: -168px;
  width: 166px;
  height: 60px;
}
.call .callzixuns {
  display: none;
  position: absolute;
  top: -4px;
  left: -178px;
  width: 176px;
  height: 60px;
}
.call .callmails {
  display: none;
  position: absolute;
  top: -4px;
  left: -210px;
  width: 206px;
  height: 60px;
}
.call .J {
  display: none;
}
.callqr:hover .qrcode,
.callphone:hover .callphones,
.callmail:hover .callmails,
.callzixun:hover .callzixuns {
  display: block;
}
@media (max-width: 866px) {
  .call{ display: none}
  .information .top{margin: 140px 0 0 0}
  .centercontent{ margin: 20px auto 20px auto;width: 95%;}
}
</style>
