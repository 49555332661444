<template>
  <div class="nav">
    <div class="navC">
      <div class="navLogo">
        <router-link to="/">
          <img src="../assets/images/logo.png" alt=""/>
        </router-link>
      </div>
      <ul>
        <li><router-link to="/">主頁</router-link></li>
        <li @click="serviceMove">
          <router-link to="/">我們的服務</router-link>
        </li>
        <li><router-link to="/information/">兌換咨詢</router-link></li>
        <li @click="findMove"><router-link to="/">聯絡我們</router-link></li>
        <el-button class="navbtn" size="mini">
          <v-reserve></v-reserve>
        </el-button>
      </ul>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import reserve from "./reserve";
export default {
  data() {
    return {};
  },
  methods: {
    showDialog() {
      this.dialogVisible = true;
    },
    serviceMove() {
      this.$router.push({ path: "/" });
      $("html, body").animate({ scrollTop: $(".service").offset().top }, 1200);
    },
    findMove() {
      this.$router.push({ path: "/" });
      $("html, body").animate({ scrollTop: $(".find").offset().top }, 1200);
    },
    move() {
      let h = $("header").height();
      $(window).scroll(function () {
        if ($(window).scrollTop() >= h) {
          $("nav").css({ position: "fixed", top: "0", background: "white" });
        } else {
          $("nav").css({ position: "static", zIndex: "10" });
        }
      });
    },
    foldOff() {
      $(".fold").css({ left: "0", transition: "all 0.8s ease-in-out" });
      $(".bg").css("display", "block");
      $(".el-unfold").css("display", "block");
    },
    foldOn() {
      $(".fold").css({ left: "-60%", transition: "all 0.8s ease-in-out" });
      $(".bg").css("display", "none");
      $(".el-unfold").css("display", "none");
    },
  },
  mounted() {
    this.move();
  },
  components: {
    "v-reserve": reserve,
  },
};
</script>
<style lang="scss">
@import "../common/font/font.css";
.nav {
  font-family: Regular;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  background: white;
  z-index: 99999;
}
.navC {
  width: 70%;
  height: 70px;
  line-height: 70px;
  margin: auto;
}
.navC > div {
  float: left;
  margin-left: 50px;
}
.navC > ul {
  float: right;
  margin-right: 50px;
}
.navC > ul > li {
  display: inline-block;
  padding: 0 20px;
}
.navC > ul > li > a {
  color: black;
  text-decoration: none;
}
.navC > ul > button {
  // height: 70px;
  color: white;
  background: #e60012;
  border: none;
  border-radius: 10px;
}
.navC > ul > button span {
  color: white;
}
.navC > ul > li:hover a {
  color: #e60012;
  cursor: pointer;
}
@media (max-width: 866px) {
  .nav {
    height: 140px;
  }
  .navC {
    width: 95%;
    text-align: center;
    height: auto;
    line-height: 0;
  }
  .navC > div {
    float: none;
  }
  .navC > div {
    margin-left: 0;
  }
  .navC > ul {
    float: none;
    margin-right: 0px;
  }
  .navC > ul > li {
    padding: 0 2px;
    font-size: 12px;
  }
}
</style>
<style>
.nav .el-button.navbtn.el-button--default.el-button--mini:hover {
  color: white;
  background-color: red;
}
</style>
