<template>
  <div class="cmarkets5">
    <v-nav></v-nav>
    <img src="../assets/images/banner1.jpg" alt="" />
    <div class="center">
      <div class="content">
        <h2>人民幣急跌確認中國挑戰美元敗陣。分析:國際市場寧沽人仔要美金</h2>
        <p>
          摘要:人民幣在岸及離岸價均創近7個月新低,當中離岸人民幣更一度大跌400基點。雖然中共一直高調將人民幣國際化,聯合部分發展中國家挑戰美元「霸權」地位,但最終敵不過市場力量。
        </p>
        <p>
          人民幣在岸及離岸價均創近7個月新低,當中離岸人民幣更一度大跌400基點。雖然中共一直高調將人民幣國際化,聯合部分發展中國家挑戰美元「霸權」地位,但最終敵不過市場力量。分析認為,國際市場上已失去了吸納人民幣的能力,寧願沽走人民幣也要回美金。
        </p>
        <p>
          人民幣兌美元匯率周一(26日)持續疲軟,在岸與離岸人民幣兌美元均跌破7.23關口,刷新去年11月尾以來新低。截至同日晚上7時半,在岸人民幣兌美元報7.2367,跌幅為429個基點;離岸人民幣兌美元報7.2415,跌幅為249個基點。澎湃新聞報道說,今年以來,人民幣對美元在岸匯率累計貶值約4%,離岸匯率累計下跌約4.5%。
        </p>
        <p>
          雖然中共一直希望人民幣國際化,例如帶動「一帶一路」沿線國家以人民幣作為跨境支付貨幣、中俄雙方同意用人民幣交易、巴西與中國簽訂本幣貿易結算協議、中法完成首單液化天然氣人民幣結算交易等。種種跡象顯示人民幣走向國際,為何離岸人民幣現在反而會大跌？
        </p>
        <h3>分析:國際市場上沒有接回人民幣的能力</h3>
        <p>
          經濟時事評論員利世民周二(27日)接受本台訪問,說雖然人民幣致力走向國際化,近日阿根廷也用人民幣交易,不用美金,出現劣幣驅逐良幣的現象。但當國際市場上沒有接回人民幣的能力,只會令中國匯率跌
        </p>
        <p>
          利世民說:我拿到人民幣,難道放在抽櫃下？至少我也要有一些東西買,但問題是現在沒有一個國際的人民幣債券,即是說我拿了人民幣不能收息。難道我拿回去中銀存進香港戶口,你給我利息嗎？因為現在人民幣利息又跌,所以我第一件事要麼中國大陸你賣一些貨給我,如果沒有貨出口,我就沽人民幣要美金,這就是離岸人民幣跌的原因。
        </p>
        <p>
          至於人民幣對美元在岸匯率也貶值,利世民表示現時中國存款及貸款的利息都在下降,中國國內有錢人自然也想去買外幣資產。
        </p>
        <h3>分析:港交所想推廣港幣人民幣交易掛鉤。連帶受拖累</h3>
        <p>
          事實上,離岸人民幣最大的市場在香港。自2009年,中國為了提高人民幣的國際地位,在香港市場開放人民幣的交易,從而形成了離岸人民幣。但當離岸人民幣大跌,對香港有何實際影響？金融學者司令向本台指出,港交所推廣港幣與人民幣交易掛鉤,在購買港股時,部分可用人民幣結算,司令認為會有這些影響:「如果往這個方向轉的時候,你也看到離岸人民幣大跌的情況下,很明顯對於港幣的幣值也會產生連帶拖累效應,港幣接下來,我預期也會出現疲軟跡象,行情也會萎縮。
        </p>
        <p>
          司令續指,港交所最重要是融資平台,以便利化組成,若港幣受離岸人民幣拖累導致疲軟,那麼很多以港幣計價的金融資產,在港交所裡會縮水,打擊投資者信心。
        </p>
        <p>
          利世民也提到,雖然當局不斷想推出人民幣計價的港股,但港元及人民幣匯率又不一致,當人民幣匯率不斷跌,反而令人覺得沒必要手持人民幣計價的港股,最後人民幣需求都是沒有增加。
        </p>
        <p>
          對於近期人民幣兌美元匯率的波動,人民銀行副行長、國家外匯管理局局長潘功勝在本月8日舉行的第14屆「陸家嘴論壇」發表主題演講時表示,4月中旬以來,受內外部多種因素影響,尤其是美元指數在美國債務上限問題、中小銀行風險問題推升避險情緒、美聯儲加息預期有所升溫的推動下走強,疊加國內經濟恢復基礎尚不穩固,人民幣匯率出現了一些波動。
        </p>
        <h3>消息:中國國有大行「出手」在即期外匯市場拋售美元</h3>
        <p>
          不過至周二則有新轉向,路透社引述消息人士表示,中國國有大行「出手」在即期外匯市場拋售美元,試圖阻止人民幣跌勢。離岸已回升至7.21361;在岸人民幣亦獲支撐,已升至7.2092水平。
        </p>
      </div>
    </div>
    <!-- prompt -->
    <div class="p">
      <p>
        免责声明:文章来至网络,文章表达观点不代表本站观点,文章版权属于原作者所有,若有侵权,请联系本站站长处理！
      </p>
    </div>
    <v-footer></v-footer>
  </div>
</template>

<script>
import Nav from "./nav";
import Footer from "./footer";
export default {
  name: "cmarkets5",
  data() {
    return {};
  },
  components: {
    "v-nav": Nav,
    "v-footer": Footer,
  },
};
</script>

<style scpped>
.cmarkets5 {
  background: #eee;
}
.cmarkets5 > div:nth-child(1) {
  background: white;
}
.center {
  width: 85%;
  margin: 2% auto;
}
.center .content {
  width: 70%;
  margin: auto;
}
.cmarkets5 .content p {
  margin: 2% 0;
}
h2 {
  text-align: center;
}
.p {
  text-align: center;
  padding: 3% 0;
  background: white;
}
</style>
<style>
</style>
