module.exports = {
  message: {
    header: {
      home: '主页',
      exchange: '货币汇率',
      bank: '银行开户',
      fuwu: '我们的服务',
      call: '联系我们',
      address: '香港九龙观塘开源道63号福昌大厦地下A8舖'
    },
    kaihu: {
      open: '助你香港开户',
      operation: '运作方便',
      operation_content: '香港银行账户可以申请网上银行，方便日常操作。',
      free: '外汇自由',
      free_content: '香港银行账户可以自由调拨资金，没有外汇管制，资金流动性强、自主性高。',
      confidentiality: '保密性强',
      confidentiality_content: '香港银行账户任何机关没有法庭判定书无权检查、受私隐法保护。',
      access: '存取灵活',
      access_content: '香港银行账户自由存取现金。'
    },
    content: {
      huilv: '汇率',
      find: '查询',
      wire: '电汇价',
      data: '更新时间',
      wiremoney: '电汇货币',
      bug: '买入',
      mai: '卖出'
    },
    Global: {
      business: '全球汇款业务',
      country: '70个国家',
      service: '最优质的服务',
      experience: '最良好的体验',
      hotline: '咨询热线'
    },
    query: {
      process: '汇款流程',
      case: '及案例',
      user: '汇港币的到指定用户',
      transfer: '请将港币XXXX转账到以下账户:中国银行/ Chan Tai Man/012XXXXXXXX',
      screenshot: '转账完成提供截图',
      screenshotM: '将转账的记录截图给我方',
      ziliao: '提供收款账号资料',
      ziliao_content: '请提供你的大陆收款账户信息，包括：账户号码，支行名称，账户名，账户号码，款项用途，我们马上为您制单',
      huan: '我方确认立即找换',
      huan_content: '我方确认收款后，将一个小时内付款',
      shouju: '付款通知给予收据',
      shouju_content: '付款后将有收据返回给您'
    },
    service: {
      we: '我们的',
      services: '服务',
      zhuying: '主营货币',
      zhuying_content: '主营港币、人民币、日元、美元及其他各国货币。',
      zhifu: '支付手段',
      zhifu_content: '支持微信，支付宝等支付工具。',
      duihuan: '全球兌換',
      duihuan_content: '中港澳及世界各地汇款，现金交易，外币兑换，人民币兑换外币等。',
      kuajing: '免费跨境服务咨询',
      kuajing_content: '我们专业的香港团队为你提供任何跨境服务咨询，包括跨境汇款，香港保险等等，希望在跨境任何业务都能够帮到你。',
      yuding: '外币兌換预订服务',
      yuding_content: '不希望去到找换店先发现没有需要的外币？不用担心，我们提供线上或致电预定外币，超过40种不同的货币等你来换。',
      waibi: '外币兌換预订服务',
      waibi_content: '不希望去兑换外币的时候白跑一趟？不用担心，我们提供线上或致电预定外币，超过40种不同的货币等你来换。'
    },
    find: {
      find: '寻找',
      we: '我们',
      tianxia: '汇通天下，无处不达',
      tianxia_content: '我们为跨境进出口货物贸易、服务贸易行业提供专门的跨境汇款解决方案，手续简单、价格更低、速度更快！',
      gongjiao: '公交路线',
      gongjiao_content1: '深圳湾直通巴士或皇岗直通巴士',
      gongjiao_content2: '观塘APM->往巧明街出口左转->步行20米对面',
      ditie: '地铁路线',
      ditie_content1: '观塘站B3出口->往开源道直行->汇丰银行',
      ditie_content2: '右后转见对面'
    },
    footer: {
      call: '联系方式:94264039',
      tell: '外币兑换电话(TEL):34626432',
      address: '地址(ADDRESS):香港九龙观塘开源道63号福昌大厦地下A8舖',
      fax: '传真(FAX):34626432'
    },
    exchange: {
      title1: '你所拥有的',
      title2: '你所获得的',
      button: '网上预约',
      huilv: '汇率',
      liji: '立即预约',
      back: '返回'
    },
    en: '简体',
    zh: '繁体'
  }
}
