<template>
  <div class="cmarkets2">
      <v-nav></v-nav>
      <img src="../assets/images/banner1.jpg" alt="">
      <div class="center">
          <div class="content">
            <h2>中國雙邊結算中人民幣佔比首超美元</h2>
            <p>提要：根據中國國家外匯管理局的統計數據，《日本經濟新聞》7月25日按照結算貨幣進行分類，對企業、個人和投資者的跨境交易進行統計。統計數據顯示，2023年第二季度，人民幣在中國跨境交易中佔比達49%，首次超過美元。這主要是因為中國資本市場更加開放，中俄採用兩國人民幣結算的貿易增多。</p>
            <h3>整体回顾</h3>
            <p>報道稱，此次的統計不包括不直接經由中國的第三國之間的貿易和資本交易的人民幣結算。</p>
            <p>然而，這並不代表世界其他地區的交易情況。環球銀行間金融通訊協會 （SWIFT）的數據顯示，截至2023年6月，美元在全球總結算額（包括第三國間）中所佔比例為42.02%，位居榜首。人民幣則以2.77%位居第五，排在歐元、英鎊和日元之後。</p>
            <p>與中國在全球國內生產總值（GDP）中所佔比例相比，人民幣結算所佔的份額仍然很少。不過，該份額已較5年前的1.81%有所上升，存在感正逐漸提高。其驅動力就是以中國經濟實力為背景的雙邊結算。</p>
            <p>中國人民銀行的統計顯示，2022年跨境人民幣收付總額為42.1萬億元。其中，資本項目為31.6萬億元，約佔75%，貿易等經常項目為10.5萬億元。</p>
            <p>2023年4月至6月，以人民幣計價的跨境結算同比增長11%，達1.5104萬億美元。從可追溯的自2010年以來的數據來看，這是人民幣季度表現首次優於美元。</p>
            <p>報道認為，雖然全球範圍內人民幣結算所佔比例不到3%，但「去美元化」開始取得進展。而人民幣結算的規模擴大主要源於兩個因素：資本市場開放和貿易結算「去美元化」。</p>
            <p>中國已向外國投資者打開通過香港交易人民幣計價的股票和債券的大門。2014年11月，「滬港通」機制啟動；2017年7月，「債券通」正式開通；2022年，交易所買賣基金（ETF）被納入互聯互通機制；2023年又啟動利率互換市場互聯互通。</p>
            <p>在貿易方面，俄羅斯因2022年以來受到西方制裁，基本被排除在美元和歐元結算體系之外，而在與中方的石油交易中俄羅斯使用人民幣結算。</p>
            <p>報道稱，未來人民幣結算所佔份額很可能將進一步提高。</p>
            <p>中國銀行業協會貿易金融專業委員會日前發布的《人民幣國際化報告（2022—2023）》指出，2022年人民幣跨境業務發展總體呈現3個特點：一是貿易和直接投資跨境人民幣結算繼續保持快速增長；二是證券投資業務繼續成為推動人民幣跨境使用增長的支撐力量；三是人民幣跨境支付系統（CIPS）在人民幣跨境支付清算服務方面發揮更加重要的作用。</p>
            <p>目前，全球「去美元化」浪潮湧現，許多新興經濟體正積極尋求減少對美元的依賴。</p>
            <p>美國智庫「昆西治國方略研究所」（Quincy Institute for Responsible Statecraft）曾刊文警告美國政府，全球去美元化已勢不可擋，且自美政府決定對俄實施全面制裁併將其從全球金融體系中剔除以來，這趨勢愈演愈烈。數據顯示，全球以美元計價的儲備份額從2001年的73%下降到2023年的58%。</p>
        </div>
      </div>
      <!-- prompt -->
      <div class="p">
          <p>免责声明：文章来至网络，文章表达观点不代表本站观点，文章版权属于原作者所有，若有侵权，请联系本站站长处理！</p>
      </div>
      <v-footer></v-footer>
  </div>
</template>

<script>
import Nav from './nav'
import Footer from './footer'
export default {
  name: 'cmarkets2',
  data () {
    return {

    }
  },
  components: {
    'v-nav': Nav,
    'v-footer': Footer
  }
}
</script>

<style scpped>
    .cmarkets2{background: #eee;}
    .cmarkets2>div:nth-child(1){background: white;}
    .center{
        width: 85%;
        margin: 2% auto;
    }
    .center .content{
        width: 70%;
        margin: auto;
    }
    .cmarkets2 .content p{
        margin: 2% 0;
    }
    h2{text-align: center;}
    .p{text-align: center;padding: 3% 0;background: white;}
</style>
<style>

</style>
