module.exports = {
  message: {
    header: {
      home: '主頁',
      exchange: '貨幣匯率',
      bank: '銀行開戶',
      fuwu: '我們的服務',
      call: '聯繫我們',
      address: '香港九龍觀塘開源道63號福昌大廈地下A8舖'
    },
    kaihu: {
      open: '助你香港開戶',
      operation: '運作方便',
      operation_content: '香港銀行賬戶可以申請網上銀行，方便日常操作。',
      free: '外匯自由',
      free_content: '香港銀行賬戶可以自由調撥資金，沒有外匯管制，資金流動性強、自主性高。',
      confidentiality: '保密性强',
      confidentiality_content: '香港銀行賬戶任何機關沒有法庭判定書無權檢查、受私隱法保護。',
      access: '存取靈活',
      access_content: '香港銀行賬戶自由存取現金。'
    },
    content: {
      huilv: '匯率',
      find: '查詢',
      wire: '電匯價',
      data: '更新時間',
      wiremoney: '電匯貨幣',
      bug: '買入',
      mai: '賣出'
    },
    Global: {
      business: '全球匯款業務',
      country: '70個國家',
      service: '最優質的服務',
      experience: '最良好的體驗',
      hotline: '咨詢熱綫'
    },
    query: {
      process: '匯款流程',
      case: '及案例',
      user: '匯港幣的到指定用戶',
      transfer: '請將港幣XXXX轉賬到以下賬戶:中國銀行/ Chan Tai Man/012XXXXXXXX',
      screenshot: '轉賬完成提供截圖',
      screenshotM: '將轉賬的記錄截圖給我方',
      ziliao: '提供收款賬號資料',
      ziliao_content: '請提供妳的大陸收款賬戶信息，包括：賬戶號碼，支行名稱，賬戶名，賬戶號碼，款項用途，我們馬上為您制單',
      huan: '我方確認立即找換',
      huan_content: '我方確認收款後，將壹個小時內付款',
      shouju: '付款通知給予收據',
      shouju_content: '付款後將有收據返回給您'
    },
    service: {
      we: '我們的',
      services: '服務',
      zhuying: '主營貨幣',
      zhuying_content: '主營港幣、人民幣、日元、美元及其他各國貨幣。',
      zhifu: '支付手段',
      zhifu_content: '支持微信，支付寶等支付工具。',
      duihuan: '全球兌換',
      duihuan_content: '中港澳及世界各地匯款，現金交易，外幣兌換，人民幣兌換外幣等。',
      kuajing: '免費跨境服務咨詢',
      kuajing_content: '我們專業的香港團隊為你提供任何跨境服務咨詢，包括跨境匯款，香港保險等等，希望在跨境任何業務都能夠幫到你。',
      yuding: '外幣兌換預訂服務',
      yuding_content: '不希望去到找換店先發現沒有需要的外幣？不用擔心，我們提供線上或致電預定外幣，超過40種不同的貨幣等你來換。',
      waibi: '外幣兌換預訂服務',
      waibi_content: '不希望去兌換外幣的時候白跑壹趟？不用擔心，我們提供線上或致電預定外幣，超過40種不同的貨幣等妳來換。'
    },
    find: {
      find: '尋找',
      we: '我們',
      tianxia: '匯通天下，無處不達',
      tianxia_content: '我們為跨境進出口貨物貿易、服務貿易行業提供專門的跨境匯款解決方案，手續簡單、價格更低、速度更快！',
      gongjiao: '公交路線',
      gongjiao_content1: '深圳灣直通巴士或皇崗直通巴士',
      gongjiao_content2: '觀塘APM->往巧明街出口左轉->步行20米對面',
      ditie: '地鐵路線',
      ditie_content1: '觀塘站B3出口->往開源道直行->匯豐銀行',
      ditie_content2: '右後轉見對面'
    },
    footer: {
      call: '聯係方式:94264039',
      tell: '外幣兌換電話(TEL):34626432',
      address: '地址(ADDRESS):香港九龍觀塘開源道63號福昌大廈地下A8舖',
      fax: '傳真(FAX):34626432'
    },
    exchange: {
      title1: '你所擁有的',
      title2: '你所獲得的',
      button: '網上預約',
      huilv: '匯率',
      liji: '立即預約',
      back: '返回'
    },
    en: '簡體',
    zh: '繁體'
  }
}
