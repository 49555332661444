<template>
    <div>
        <v-nav></v-nav>
        <h1>汇通</h1>
        <v-footer></v-footer>
    </div>
</template>
<script>
import Reserve from './reserve' // 网上预约组件
import Nav from './nav' // 导航组建
import Footer from './footer' // 尾部组建
export default {
  data () {
    return {

    }
  },
  components: {
    'v-reserve': Reserve,
    'v-nav': Nav,
    'v-footer': Footer
  }
}
</script>
<style>

</style>
