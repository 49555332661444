import Vue from 'vue'
import App from './App'
import router from './router'
import VueAnalytics from 'vue-analytics'
import BootstrapVue from 'bootstrap-vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/font/iconfont.css'
import BaiduMap from 'vue-baidu-map'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import VueLazyload from 'vue-lazyload'
import moment from 'moment'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
Vue.use(BootstrapVue)
Vue.use(ElementUI)
Vue.use(BaiduMap, {
  ak: 'oXiQEIF40a3pneN7FLhALf3940ldd5lE'
})
Vue.prototype.$moment = moment
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title和description */
  if (to.meta.title) {
    document.title = to.meta.title
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)
    document.querySelector('meta[name="Keywords"]').setAttribute('content', to.meta.keywords)
  }
  next()
})
moment.locale('zh-cn')

Vue.config.productionTip = false
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 4,
  listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove']
})
const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': require('./common/lang/zh'),
    'en': require('./common/lang/en')
  }
})

// init GA
if (process.env.GA) {
  Vue.use(VueAnalytics, {
    id: process.env.GA,
    disableScriptLoader: true,
    router,
    autoTracking: {
      pageviewOnLoad: false
    }
  })
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  i18n,
  components: { App },
  template: '<App/>'
})
