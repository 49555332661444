<template>
  <div class="cmarkets3">
      <v-nav></v-nav>
      <img src="../assets/images/banner1.jpg" alt="">
      <div class="center">
          <div class="content">
            <h2>中國一個月內連續降低美元存款利率 以阻人民幣繼續貶值</h2>
            <p>摘要：中國主要國有銀行在一個月內連續第二次降低美元存款利率，以阻止人民幣貶值進一步下滑。</p>
            <h3>整体回顾与前瞻</h3>
            <p>路透社援引七位不願透露姓名的熟悉內情人士的消息說，五大國有銀行將多數美元儲蓄的利率從先前的4.3%降到了2.8%。</p>
            <p>報導表示，通常向國有銀行就美元存款利率發布指導的中國人民銀行沒有立即對此事發表評論。而工商、中國銀行、農行、建行和交通銀行也都沒有立即回應置評請求。</p>
            <p>交易者和分析師表示，政策制定者擔心人民幣的持續貶值既阻礙外國投資，又刺激資金外流，希望降低美元的存款利率，向人民幣利率靠攏。人民幣的利率已經降低，以支撐萎靡的經濟。</p>
            <p>人民幣是今年表現最差的亞洲貨幣之一。由於中國經濟增長減緩及與美國的美元收益差距日益擴大，人民幣已經貶值了近5%。</p>
            <p>報導援引一位分析師的話說，降低美元存款利率可以阻礙家庭將儲蓄存入收益較高的美元存款，也可以促使中國公司，通常是出口商，最後以人民幣結算外匯收入。</p>
            <p>兩位消息人士說，新的美元存款利率7月1日開始生效，並稱一些銀行對大額存單也不提供高於2.8%的利率。銀行通常為高於100萬美元的大額存單提供更高的利率。</p>
            <p>報導表示，中國的央行最近採取步驟阻止人民幣對美元繼續貶值，制定強於預期的每日浮動指標。同時，國有銀行也時常地在離岸及國內市場拋售美元。</p>
            <p>這是中國當局一個月內第二次削減美元存款利率。 6月初，五大國有銀行已經將存款利率從此前的5.3%下調了100個基點。</p>
            <p>消息人士上週告訴路透社說，中國的央行近期調查了一些外國銀行為客戶的美元存款提供的利率。</p>
            <p>中國人民銀行上週五說，將繼續維持人民幣的基本穩定，並防範匯率大幅度波動的風險。</p>
            <p>另據官媒央廣網報導，目前，中國主要銀行調低美元存款利率的影響，已經傳導到外匯市場。 7月3日，銀行間外匯市場人民幣對美元匯率中間價報7.2175元，結束了連續貶值的走勢，較前一交易日調升了101個基點。截至7月4日9點，更多反映國際投資者預期的離岸人民幣對美元匯率徘徊在7.246附近，較前一個交易日創下的年度低點7.2855回升了300多個基點。</p>
        </div>
      </div>
      <!-- prompt -->
      <div class="p">
          <p>免责声明：文章来至网络，文章表达观点不代表本站观点，文章版权属于原作者所有，若有侵权，请联系本站站长处理！</p>
      </div>
      <v-footer></v-footer>
  </div>
</template>

<script>
import Nav from './nav'
import Footer from './footer'
export default {
  name: 'cmarkets3',
  data () {
    return {

    }
  },
  components: {
    'v-nav': Nav,
    'v-footer': Footer
  }
}
</script>

<style scpped>
    .cmarkets3{background: #eee;}
    .cmarkets3>div:nth-child(1){background: white;}
    .center{
        width: 85%;
        margin: 2% auto;
    }
    .center .content{
        width: 70%;
        margin: auto;
    }
    .cmarkets3 .content p{
        margin: 2% 0;
    }
    h2{text-align: center;}
    .p{text-align: center;padding: 3% 0;background: white;}
</style>
<style>

</style>
